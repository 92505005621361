import React, { useState } from "react";
import Logo from "../Images/Cert-Logo.png";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const Register = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    emailAddress: "",
    month: "",
    day: "",
    year: "",
    gender: "",
    otp: "",
    companyName: "",
    designation: "",
    Address: "",
    phoneNumber: "",
  });

  const [errors, setErrors] = useState({});
  const [currentStep, setCurrentStep] = useState(1);
  const navigate = useNavigate();

  const steps = [
    "Enter Name",
    "Basic Information",
    "Contact Information",
    "Sign-In Details",
    "Verification",
    "Company Information",
  ];

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const validateStep1 = () => {
    const newErrors = {};
    if (!formData.firstName.trim()) {
      newErrors.firstName = "First name is required.";
    }
    if (!formData.lastName.trim()) {
      newErrors.lastName = "Last name is required.";
    }
    return newErrors;
  };

  const validateStep2 = () => {
    const newErrors = {};
    const currentYear = new Date().getFullYear();
    if (!formData.month) {
      newErrors.month = "Month is required.";
    }
    if (
      !formData.day ||
      isNaN(formData.day) ||
      formData.day < 1 ||
      formData.day > 31
    ) {
      newErrors.day = "Valid day is required.";
    }
    if (!formData.year || isNaN(formData.year) || formData.year.length !== 4) {
      newErrors.year = "Valid year is required.";
    } else if (parseInt(formData.year) > currentYear) {
      newErrors.year = `Year cannot exceed ${currentYear}.`;
    }
    if (!formData.gender) {
      newErrors.gender = "Gender is required.";
    }
    return newErrors;
  };

  const validateStep3 = () => {
    const newErrors = {};
    if (!formData.phoneNumber) {
      newErrors.phoneNumber = "Phone Number is required.";
    }
    return newErrors;
  };

  const validateStep4 = () => {
    const newErrors = {};
    if (!formData.email) {
      newErrors.email = "Email is required.";
    }
    return newErrors;
  };

  const validateStep5 = () => {
    const newErrors = {};
    if (!formData.otp) {
      newErrors.otp = "OTP is required.";
    }
    return newErrors;
  };

  const validateStep6 = () => {
    const newErrors = {};
    if (!formData.companyName) {
      newErrors.companyName = "Company Name is required.";
    }
    if (!formData.address) {
      newErrors.address = "Company Address is required.";
    }
    if (!formData.designation) {
      newErrors.designation = "Designation is required.";
    }
    return newErrors;
  };

  const handleNext = () => {
    let validationErrors = {};
    if (currentStep === 1) {
      validationErrors = validateStep1();
    } else if (currentStep === 2) {
      validationErrors = validateStep2();
    } else if (currentStep === 3) {
      validationErrors = validateStep3();
    } else if (currentStep === 4) {
      validationErrors = validateStep4();
    } else if (currentStep === 5) {
      validationErrors = validateStep5();
    } else if (currentStep === 6) {
      validationErrors = validateStep6();
    }

    if (Object.keys(validationErrors).length === 0) {
      setErrors({});
      setCurrentStep((prev) => prev + 1);
    } else {
      setErrors(validationErrors);
    }
  };

  const handleChangeOtp = (index, value) => {
    if (!isNaN(value) && value.length <= 1) {
      const updatedOtp = [...formData.otp];
      updatedOtp[index] = value;
      setFormData({ ...formData, otp: updatedOtp });
    }
  };

  const handleOtpKeyPress = (index, event) => {
    if (event.key === "Backspace" && formData.otp[index] === "") {
      if (index > 0) {
        document.getElementById(`otp-${index - 1}`).focus();
      }
    } else if (!isNaN(event.key) && index < formData.otp.length - 1) {
      document.getElementById(`otp-${index + 1}`).focus();
    }
  };

  const handlePhoneChange = (value) => {
    setFormData({ ...formData, phoneNumber: value });
  };

  const handleSubmit = () => {
    const validationErrors = {};
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    toast.success("Registered successfully!", {
      position: "bottom-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

    setTimeout(() => {
      navigate("/home");
    }, 3000);
  };

  return (
    <div className="flex justify-center items-center h-screen bg-gray-100">
      <div className="bg-white shadow-lg rounded-3xl px-8 w-[950px] h-[500px]">
        <div>
          <img src={Logo} className="h-[60px] mt-8" alt="Logo" />
        </div>

        <div className="flex items-center mt-8 ml-4">
          {steps.map((step, index) => (
            <div
              key={index}
              className={`flex items-center gap-2 ${
                index + 1 === currentStep ? "text-red-600" : "text-gray-400"
              }`}
            >
              <div
                className={`w-8 h-8 rounded-full flex justify-center items-center border-2 ${
                  index + 1 === currentStep
                    ? "border-red-600 bg-red-100"
                    : "border-gray-300"
                }`}
              >
                {index + 1}
              </div>
              <p className="text-[10px]">{step}</p>
              {index < steps.length - 1 && <div className="w-6 border-b"></div>}
            </div>
          ))}
        </div>

        <div className="flex flex-row justify-between mt-5 items-start gap-6">
          {/* Left Side */}
          <div className="flex flex-col w-1/2">
            {currentStep === 1 && (
              <>
                <h1 className="text-3xl pl-4 mt-4 text-[36px] text-[#1F1F1F]">
                  Register your Account
                </h1>
                <p className="pl-5 mt-4 text-[#1F1F1F] text-[17px]">
                  Enter your name
                </p>
              </>
            )}

            {currentStep === 2 && (
              <>
                <h1 className="text-3xl pl-4 mt-4 text-[36px] text-[#1F1F1F]">
                  Basic Information
                </h1>
                <p className="pl-5 mt-4 text-[#1F1F1F] text-[17px]">
                  Enter your Birthday and Gender
                </p>
              </>
            )}

            {currentStep === 3 && (
              <>
                <h1 className="text-3xl pl-4 mt-4 text-[36px] text-[#1F1F1F]">
                  Contact Information
                </h1>
                <p className="pl-5 mt-4 text-[#1F1F1F] text-[17px]">
                  Enter your Contact Number
                </p>
              </>
            )}

            {currentStep === 4 && (
              <>
                <h1 className="text-3xl pl-4 mt-4 text-[36px] text-[#1F1F1F]">
                  How you'll sign in
                </h1>
                <p className="pl-5 mt-4 text-[#1F1F1F] w-[400px] text-[17px]">
                  Enter your Gmail address for signing in to our website
                </p>
              </>
            )}

            {currentStep === 5 && (
              <>
                <h1 className="text-3xl pl-4 mt-4 text-[36px] text-[#1F1F1F]">
                  Verification Process
                </h1>
                <p className="pl-5 mt-4 text-[#1F1F1F] w-[400px] text-[17px]">
                  Enter the OTP... OTP has been sent to your registered Email
                  Address.
                </p>
              </>
            )}

            {currentStep === 6 && (
              <>
                <h1 className="text-3xl pl-4 mt-4 text-[36px] text-[#1F1F1F]">
                  Company Information
                </h1>
                <p className="pl-5 mt-4 text-[#1F1F1F] w-[400px] text-[17px]">
                  Enter the Company Name, address and designation...
                </p>
              </>
            )}
          </div>

          {/* Right Side */}
          <div className="flex flex-col w-1/2">
            {currentStep === 1 && (
              <>
                <div className="mb-4">
                  <label className="block text-gray-700 text-sm font-bold mb-2">
                    First Name
                  </label>
                  <input
                    type="text"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleChange}
                    autoComplete="off"
                    maxLength={40}
                    className={`w-full px-4 py-2 border rounded-lg focus:outline-none ${
                      errors.firstName ? "border-red-500" : "border-gray-300"
                    }`}
                  />
                  {errors.firstName && (
                    <p className="text-red-500 text-sm mt-1">
                      {errors.firstName}
                    </p>
                  )}
                </div>

                <div className="mb-4">
                  <label className="block text-gray-700 text-sm font-bold mb-2">
                    Last Name
                  </label>
                  <input
                    type="text"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleChange}
                    autoComplete="off"
                    maxLength={40}
                    className={`w-full px-4 py-2 border rounded-lg focus:outline-none ${
                      errors.lastName ? "border-red-500" : "border-gray-300"
                    }`}
                  />
                  {errors.lastName && (
                    <p className="text-red-500 text-sm mt-1">
                      {errors.lastName}
                    </p>
                  )}
                </div>
                <div className="flex-grow absolute bottom-40 right-60 flex items-end justify-end">
                  <button
                    onClick={handleNext}
                    className="text-white bg-red-600 rounded-lg px-6 py-2 hover:bg-red-700"
                  >
                    Next
                  </button>
                </div>
              </>
            )}

            {currentStep === 2 && (
              <>
                <div className="mb-4 flex flex-row gap-2 pt-5">
                  <div>
                    <select
                      name="month"
                      value={formData.month}
                      onChange={handleChange}
                      className=" px-4 py-2 border rounded-lg focus:outline-none"
                    >
                      <option value="">Month</option>
                      <option value="January">January</option>
                      <option value="February">February</option>
                      <option value="March">March</option>
                      <option value="April">April</option>
                      <option value="May">May</option>
                      <option value="June">June</option>
                      <option value="July">July</option>
                      <option value="August">August</option>
                      <option value="September">September</option>
                      <option value="October">October</option>
                      <option value="November">November</option>
                      <option value="December">December</option>
                    </select>
                    {errors.month && (
                      <p className="text-red-500 text-sm mt-1">
                        {errors.month}
                      </p>
                    )}
                  </div>
                  <div>
                    <input
                      type="text"
                      name="day"
                      placeholder="Day"
                      value={formData.day}
                      onChange={handleChange}
                      autoComplete="off"
                      maxLength={2}
                      className="w-full px-4 py-2 border rounded-lg focus:outline-none"
                    />
                    {errors.day && (
                      <p className="text-red-500 text-sm mt-1">{errors.day}</p>
                    )}
                  </div>
                  <div>
                    <input
                      type="text"
                      name="year"
                      placeholder="Year"
                      value={formData.year}
                      onChange={handleChange}
                      autoComplete="off"
                      maxLength={4}
                      className={`w-full px-4 py-2 border rounded-lg focus:outline-none ${
                        errors.year ? "border-red-500" : "border-gray-300"
                      }`}
                    />
                    {errors.year && (
                      <p className="text-red-500 text-sm mt-1">{errors.year}</p>
                    )}
                  </div>
                </div>

                <div className="mb-4">
                  <select
                    name="gender"
                    value={formData.gender}
                    onChange={handleChange}
                    className="w-full px-4 py-2 border rounded-lg focus:outline-none"
                  >
                    <option value="">Gender</option>
                    <option value="Female">Female</option>
                    <option value="Male">Male</option>
                    <option value="February">Rather not Say</option>
                    <option value="Others">Others</option>
                  </select>
                  {errors.gender && (
                    <p className="text-red-500 text-sm mt-1">{errors.gender}</p>
                  )}
                </div>
                <div className="flex-grow absolute bottom-40 right-60 flex items-end justify-end">
                  <button
                    type="submit"
                    onClick={handleNext}
                    className="text-white bg-red-600 rounded-lg px-6 py-2 hover:bg-red-700"
                  >
                    Next
                  </button>
                </div>
              </>
            )}

            {currentStep === 3 && (
              <>
                <div className="mb-4 pt-5">
                  <label className="block text-gray-700 text-sm font-bold mb-2">
                    Contact Number
                  </label>
                  <PhoneInput
                    country={"in"}
                    value={formData.phoneNumber}
                    onChange={handlePhoneChange}
                  />
                  {errors.phoneNumber && (
                    <p className="text-red-500 text-sm mt-1">
                      {errors.phoneNumber}
                    </p>
                  )}
                </div>
                <div className="flex-grow absolute bottom-40 right-60 flex items-end justify-end">
                  <button
                    onClick={handleNext}
                    className="text-white bg-red-600 rounded-lg px-6 py-2 hover:bg-red-700"
                  >
                    Next
                  </button>
                </div>
              </>
            )}

            {currentStep === 4 && (
              <>
                <div className="mb-4 pt-5">
                  <label className="block text-gray-700 text-sm font-bold mb-2">
                    Email Address
                  </label>
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    autoComplete="off"
                    maxLength={30}
                    className={`w-full px-4 py-2 border rounded-lg focus:outline-none ${
                      errors.email ? "border-red-500" : "border-gray-300"
                    }`}
                  />
                  {errors.email && (
                    <p className="text-red-500 text-sm mt-1">{errors.email}</p>
                  )}
                </div>
                <div className="flex-grow absolute bottom-40 right-60 flex items-end justify-end">
                  <button
                    onClick={handleNext}
                    className="text-white bg-red-600 rounded-lg px-6 py-2 hover:bg-red-700"
                  >
                    Next
                  </button>
                </div>
              </>
            )}

            {currentStep === 5 && (
              <>
                <div className="mb-4 pt-5">
                  <label className="block text-gray-700 text-sm font-bold mb-2">
                    OTP
                  </label>
                  <input
                    type="num"
                    name="otp"
                    maxLength={6}
                    value={formData.otp}
                    onChange={handleChange}
                    autoComplete="off"
                    className={`w-full px-4 py-2 border rounded-lg focus:outline-none ${
                      errors.otp ? "border-red-500" : "border-gray-300"
                    }`}
                  />
                  {errors.otp && (
                    <p className="text-red-500 text-sm mt-1">{errors.otp}</p>
                  )}
                </div>
                <div className="flex-grow absolute bottom-40 right-60 flex items-end justify-end">
                  <button
                    onClick={handleNext}
                    className="text-white bg-red-600 rounded-lg px-6 py-2 hover:bg-red-700"
                  >
                    Next
                  </button>
                </div>
              </>
            )}

            {currentStep === 6 && (
              <>
                <div className="mb-4">
                  <input
                    type="text"
                    name="companyName"
                    placeholder="Company Name"
                    maxLength={50}
                    value={formData.companyName}
                    onChange={handleChange}
                    autoComplete="off"
                    className={`w-full px-4 py-2 border rounded-lg focus:outline-none ${
                      errors.companyName ? "border-red-500" : "border-gray-300"
                    }`}
                  />
                  {errors.companyName && (
                    <p className="text-red-500 text-sm mt-1">
                      {errors.companyName}
                    </p>
                  )}
                </div>

                <div className="mb-4">
                  <input
                    type="text"
                    name="address"
                    placeholder="Company Address (ex: Bangalore)"
                    value={formData.address}
                    onChange={handleChange}
                    maxLength={60}
                    autoComplete="off"
                    className={`w-full px-4 py-2 border rounded-lg focus:outline-none ${
                      errors.address ? "border-red-500" : "border-gray-300"
                    }`}
                  />
                  {errors.address && (
                    <p className="text-red-500 text-sm mt-1">
                      {errors.address}
                    </p>
                  )}
                </div>

                <div className="mb-4">
                  <input
                    type="text"
                    name="designation"
                    placeholder="Designation"
                    maxLength={40}
                    value={formData.designation}
                    onChange={handleChange}
                    autoComplete="off"
                    className={`w-full px-4 py-2 border rounded-lg focus:outline-none ${
                      errors.designation ? "border-red-500" : "border-gray-300"
                    }`}
                  />
                  {errors.designation && (
                    <p className="text-red-500 text-sm mt-1">
                      {errors.designation}
                    </p>
                  )}
                </div>
                <div className="flex-grow absolute bottom-40 right-60 flex items-end justify-end">
                  <button
                    onClick={handleSubmit}
                    className="text-white bg-red-600 rounded-lg px-6 py-2 hover:bg-red-700"
                  >
                    Submit
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
