import React, { useEffect } from "react";
import { BrowserRouter } from 'react-router-dom';
import AllRoutes from "./Routes/AllRoutes";
import { ToastContainer } from "react-toastify";

function App() {
  return (
    <BrowserRouter className="App">
      <ToastContainer />
      <AllRoutes />
    </BrowserRouter>
  );
}

export default App;
