import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "./Navbar/Navbar";
import Bg from "../Images/BgImg.png";
import { BsArrowRightCircle, BsCheckCircleFill } from "react-icons/bs";

const Home = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [steps, setSteps] = useState([false, false, false, false]); // Track completion of each step
  const navigate = useNavigate();

  const step = [
    "Choose Module",
    "Select Certification",
    "Attend Quiz",
    "Google Review",
  ];

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowPopup(true);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  const handleModuleClick = (moduleId) => {
    setSteps((prevSteps) => {
      const newSteps = [...prevSteps];
      newSteps[0] = true; // Mark Step 1 as completed
      return newSteps;
    });
    navigate(`/module${moduleId}`, {
      state: { steps: [true, false, false, false] },
    });
  };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   alert("Form Submitted!");
  //   setSteps((prevSteps) => {
  //     const newSteps = [...prevSteps];
  //     newSteps[0] = true;
  //     return newSteps;
  //   });
  //   setShowPopup(false);
  // };

  return (
    <div className="flex flex-col min-h-screen">
      <div className="flex-shrink-0">
        <Navbar />
      </div>

      {/* {showPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg shadow-lg w-11/12 sm:w-1/2 lg:w-1/3 p-6">
            <h2 className="text-center text-2xl font-bold text-gray-800 mb-4">
              Fill Your Details to Proceed
            </h2>
            <form onSubmit={handleSubmit} className="space-y-4">
              <div>
                <label
                  htmlFor="firstName"
                  className="block text-sm font-medium text-gray-700"
                >
                  First 
                </label>
                <input
                  type="text"
                  id="firstName"
                  required
                  className="w-full p-2 border border-gray-300 rounded-lg"
                />
              </div>
              <div>
                <label
                  htmlFor="lastName"
                  className="block text-sm font-medium text-gray-700"
                >
                  Last Name
                </label>
                <input
                  type="text"
                  id="lastName"
                  required
                  className="w-full p-2 border border-gray-300 rounded-lg"
                />
              </div>
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email Address
                </label>
                <input
                  type="email"
                  id="email"
                  required
                  className="w-full p-2 border border-gray-300 rounded-lg"
                />
              </div>
              <div>
                <label
                  htmlFor="contact"
                  className="block text-sm font-medium text-gray-700"
                >
                  Contact Number
                </label>
                <input
                  type="tel"
                  id="contact"
                  required
                  className="w-full p-2 border border-gray-300 rounded-lg"
                />
              </div>
              <div>
                <label
                  htmlFor="companyName"
                  className="block text-sm font-medium text-gray-700"
                >
                  Company Name
                </label>
                <input
                  type="text"
                  id="companyName"
                  required
                  className="w-full p-2 border border-gray-300 rounded-lg"
                />
              </div>
              <div>
                <label
                  htmlFor="address"
                  className="block text-sm font-medium text-gray-700"
                >
                  Address
                </label>
                <input
                  type="text"
                  id="address"
                  required
                  className="w-full p-2 border border-gray-300 rounded-lg"
                />
              </div>
              <button
                type="submit"
                className="w-full bg-blue-600 text-white py-2 rounded-lg hover:bg-blue-700 transition duration-300"
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      )} */}

      <div
        className="relative flex-grow px-4 py-8  md:flex-row items-start"
        style={{
          backgroundImage: `url(${Bg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <h1 className="text-center text-4xl font-bold text-white mb-12 p-4">
          Welcome to the Home Page
        </h1>

        {/* Breadcrumbs */}
        <div className="flex justify-center items-center mb-16 ml-6 text-xl space-x-6">
          {step.map((step, index) => (
            <div key={index} className="flex items-center space-x-4">
              <div className="flex items-center">
                <div
                  className={`w-8 h-8 flex items-center justify-center rounded-full ${
                    steps[index] ? "bg-green-500 text-white" : "bg-gray-300"
                  }`}
                >
                  {steps[index] ? <BsCheckCircleFill /> : index + 1}
                </div>
                {index < steps.length - 1 && (
                  <div className="w-6 border-b"></div>
                )}
              </div>
              <p className="text-[20px] text-white">{step}</p>
            </div>
          ))}
        </div>

        {/* Modules */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 px-4">
          {[
            {
              title: "Awareness Training",
              description:
                "Awareness training educates employees, stakeholders, and management teams about the principles, requirements, and benefits of ISO certifications. It helps everyone in the organization understand their roles in maintaining compliance and improving processes.",
              onClick: () => handleModuleClick(1),
              number: 1,
            },
            {
              title: "Lead Implementation",
              description:
                "This module provides detailed information about the benefits and implementation of ISO certifications in various industries. ISO certifications ensure quality, efficiency, and safety in operations and compliance with international standards.",
              onClick: () => handleModuleClick(2),
              number: 2,
            },
            {
              title: "Internal Auditor",
              description:
                "This module provides detailed information about the benefits and implementation of ISO certifications in various industries. ISO certifications ensure quality, efficiency, and safety in operations and compliance with international standards.",
              onClick: () => handleModuleClick(3),
              number: 3,
            },
          ].map((module, index) => (
            <div
              key={index}
              className="relative bg-gradient-to-r from-red-400 via-yellow-300 to-red-400 shadow-lg rounded-lg p-6 pt-12 min-h-[200px] min-w-[250px] animate-floating hover:animate-hovering"
            >
              {/* Circular Number */}
              <div className="absolute top-[-20px] left-1/2 transform -translate-x-1/2 bg-red-500 text-white rounded-full h-10 w-10 flex items-center justify-center font-bold text-lg cursor-pointer" onClick={module.onClick}>
                {module.number}
              </div>
              {/* Module Content */}
              <div className="flex flex-row items-center">
                <h2 className="text-xl text-gray-800 mb-4 font-bold">
                  {module.title}
                </h2>
                <div className="ml-auto flex items-center h-full">
                  <BsArrowRightCircle
                    className="text-red-600 hover:text-red-500 cursor-pointer text-3xl"
                    onClick={module.onClick}
                  />
                </div>
              </div>
              <p className="text-gray-600">{module.description}</p>
            </div>
          ))}
        </div>

        <style jsx>{`
          /* Floating Animation */
          @keyframes floating {
            0%,
            100% {
              transform: translateY(0);
            }
            50% {
              transform: translateY(-10px);
            }
          }

          /* Hovering Zoom Animation */
          @keyframes hovering {
            0%,
            100% {
              transform: scale(1);
            }
            50% {
              transform: scale(1.1);
            }
          }

          .animate-floating {
            animation: floating 3s ease-in-out infinite;
          }

          .hover:animate-hovering {
            animation: floating 3s ease-in-out infinite,
              hovering 0.5s ease-in-out infinite;
          }
        `}</style>
      </div>
    </div>
  );
};

export default Home;
