import React from 'react';

const Navbar = () => {
  return (
    <nav className="text-white shadow-md">
      <div className="container mx-auto px-4 py-3 flex justify-center">
        <div className="navbar-logo">
          <img
            src="https://www.certvalue.com/wp-content/uploads/2022/08/Untitled-design14.gif"
            alt="Dummy Logo"
            height="80px"
            width="200px"
          />
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
