// Question Data 

export const questionsBank = {
    iso9001: [
      {
        question: "What is the primary objective of an internal audit in ISO 9001?",
        options: [
          "To punish employees for non-conformities",
          "To identify opportunities for improvement and ensure compliance",
          "To prepare for a financial audit",
          "To create marketing strategies"
        ],
        correctAnswer: "To identify opportunities for improvement and ensure compliance",
      },
      {
        question: "Which clause of ISO 9001 requires organizations to conduct internal audits?",
        options: ["Clause 7.1",
           "Clause 8.2", 
           "Clause 9.2",
           "Clause 10.3",
          ],
        correctAnswer: "Clause 9.2",
      },
      {
        question: "What is the role of an internal auditor in an ISO 9001 audit?",
        options: [
          "To recommend corrective actions",
          "To ensure compliance and report non-conformities",
          "To implement changes in processes",
          "To evaluate employee performance",
        ],
        correctAnswer: "To ensure compliance and report non-conformities"
      },
      {
        question: "What should an auditor focus on during an internal audit?",
        options: [
          "Employee satisfaction",
          "Documented procedures and their implementation",
          "Company profit margins",
          "Personal opinions about the process",
        ],
        correctAnswer: "Documented procedures and their implementation"
      },
      {
        question: "What is a Non-Conformance Report (NCR)?",
        options: [
          " A report showing the audit schedule",
          "A record of non-compliance with ISO 9001 requirements",
          "A list of completed tasks during the audit",
          "A financial performance document",
        ],
        correctAnswer: "A record of non-compliance with ISO 9001 requirements"
      },
      {
        question: "What does risk-based thinking mean in the context of ISO 9001?",
        options: [
          "Avoiding risks entirely",
          "Proactively identifying and managing risks and opportunities",
          "Delegating risk management to an external consultant",
          "Ignoring low-priority risks"
        ],
        correctAnswer: "Proactively identifying and managing risks and opportunities"
      },
      {
        question: "When conducting an audit, what is the most effective way to collect evidence?",
        options: [
          "Conducting interviews and observations",
          "Guessing based on previous audits",
          " Assuming compliance based on management statements",
          "Relying on external reports only",
        ],
        correctAnswer: "Conducting interviews and observations"
      },
      {
        question: "What is the main purpose of an audit checklist?",
        options: [
          "To identify areas where the auditor can save time",
          "To ensure all audit requirements are covered systematically",
          "To increase employee stress",
          "To document financial performance",
        ],
        correctAnswer: "To ensure all audit requirements are covered systematically"
      },
      {
        question: "What is a “corrective action” in the context of an internal audit?",
        options: [
          " A measure taken to punish employees for errors",
          "A step taken to eliminate the cause of a detected non-conformity",
          " A suggestion to improve employee performance",
          "A preventive measure for potential risks",
        ],
        correctAnswer: "A step taken to eliminate the cause of a detected non-conformity"
      },
      {
        question: "What should an auditor do if they identify a minor non-conformity during an audit?",
        options: [
          "Ignore it and focus on major non-conformities",
          "Document it and report it to management",
          "Immediately implement corrective action",
          "Escalate it to the certification body",
        ],
        correctAnswer: "Document it and report it to management"
      }
    ],
    iso14001: [
      {
        question: "What is the primary focus of ISO 14001?",
        options: [
          "To improve financial performance",
          "To enhance environmental performance and compliance",
          "To reduce employee turnover",
          "To increase customer satisfaction",
        ],
        correctAnswer: "To enhance environmental performance and compliance",
      },
      {
        question: "Which clause in ISO 14001 deals with internal audits?",
        options: ["Clause 6.1", "Clause 7.3", "Clause 9.2", " Clause 10.3"],
        correctAnswer: "Clause 9.2",
      },
      {
        question: "What is the key focus of Clause 6.1 in ISO 14001?",
        options: [
          "Employee performance evaluation",
          "Identifying and addressing environmental risks and opportunities",
          "Designing the EMS framework",
          "Preparing financial statements",
        ],
        correctAnswer: "Identifying and addressing environmental risks and opportunities",
      },
      {
        question: "What is required to be documented during an internal audit of an EMS?",
        options: [
          "Audit plans, findings, and any non-conformities",
          "Employee personal details",
          "Marketing strategies",
          " Monthly sales reports",
        ],
        correctAnswer: "Audit plans, findings, and any non-conformities",
      },
      {
        question: "What does ISO 14001 require organizations to identify and control?",
        options: [
          "Employee behaviors",
          "Environmental aspects and their impacts",
          "Customer complaints",
          "Financial risks",
        ],
        correctAnswer: "Environmental aspects and their impacts",
      },
      {
        question: "What is the purpose of an Environmental Policy under ISO 14001?",
        options: [
          "To outline the company’s financial goals",
          "To declare the organization's commitment to environmental management",
          "To replace risk management processes",
          "To comply with labor laws",
        ],
        correctAnswer: "To declare the organization's commitment to environmental management",
      },
      {
        question: "What is the significance of Clause 4.1 in ISO 14001?",
        options: [
          "To define the EMS scope",
          "To understand the organization and its context",
          "To train internal auditors",
          "To review financial performance",
        ],
        correctAnswer: "To understand the organization and its context",
      },
      {
        question: "What is a non-conformity in ISO 14001?",
        options: [
          " A deviation from financial targets",
          "A failure to meet environmental objectives or compliance requirements",
          "A disagreement between auditors",
          "A suggestion for process improvement",
        ],
        correctAnswer: "A failure to meet environmental objectives or compliance requirements",
      },
      {
        question: "What does the term life cycle perspective mean in ISO 14001?",
        options: [
          "Considering the environmental impacts of a product or service from production to disposal",
          "Managing employee performance over time",
          "Monitoring financial cycles in a business",
          "Evaluating competitor performance",
        ],
        correctAnswer: "Considering the environmental impacts of a product or service from production to disposal",
      },
      {
        question: "What is the purpose of Clause 10.2 in ISO 14001?",
        options: [
          "To establish corrective actions for non-conformities",
          "To create marketing strategies",
          "To train employees on ISO 9001",
          "To audit external suppliers",
        ],
        correctAnswer: "To establish corrective actions for non-conformities",
      }
    ],
    iso45001: [
      {
        question: "What is the primary purpose of ISO 45001?",
        options: [
          "To improve financial performance",
          "To establish a framework for managing occupational health and safety risks",
          "To enhance marketing strategies",
          "To train employees in quality management",
        ],
        correctAnswer: "To establish a framework for managing occupational health and safety risks",
      },
      {
        question: "Which clause in ISO 45001 focuses on internal audits?",
        options: [
          "Clause 6.2",
          "Clause 9.2",
          "Clause 8.5",
          " Clause 10.1",
        ],
        correctAnswer: "Clause 9.2",
      },
      {
        question: "What is the primary focus of Clause 4.1 in ISO 45001?",
        options: [
          "Identifying financial risks",
          " Understanding the organization and its context",
          " Managing external suppliers",
          "Conducting employee training",
        ],
        correctAnswer: " Understanding the organization and its context",
      },
      {
        question: "What does ISO 45001 require for hazard identification?",
        options: [
          "Ignoring minor hazards",
          "Establishing a systematic process to identify and evaluate workplace hazards",
          "Conducting hazard assessments only after incidents",
          "Relying solely on external consultants",
        ],
        correctAnswer: "Establishing a systematic process to identify and evaluate workplace hazards"
      },
      {
        question: "What is the purpose of the hierarchy of controls in ISO 45001?",
        options: [
          "To prioritize actions for mitigating occupational health and safety risks",
          "To organize financial records",
          "To train employees on quality management",
          "To evaluate supplier performance",
        ],
        correctAnswer: "To prioritize actions for mitigating occupational health and safety risks"
      },
      {
        question: "What does Clause 6.1.2 in ISO 45001 address?",
        options: [
          "Planning for actions to address risks and opportunities",
          "Employee performance reviews",
          "Marketing strategies",
          "Financial planning",
        ],
        correctAnswer: "Planning for actions to address risks and opportunities"
      },
      {
        question: "What is the role of leadership in ISO 45001?",
        options: [
          "To delegate all responsibilities to a safety officer",
          "To demonstrate commitment to the OHSMS and promote a culture of safety",
          "To focus solely on financial audits",
          "To outsource safety management",
        ],
        correctAnswer: 'To demonstrate commitment to the OHSMS and promote a culture of safety'
      },
      {
        question: "What is the purpose of worker consultation and participation in ISO 45001?",
        options: [
          "To create financial incentives for workers",
          "To ensure workers are involved in decision-making and hazard identification",
          "To replace external audits",
          "To train workers on financial planning",
        ],
        correctAnswer: "To ensure workers are involved in decision-making and hazard identification"
      },
      {
        question: "What is required under Clause 10 in ISO 45001",
        options: [
          "Continual improvement of the OHSMS based on audit findings and feedback",
          "Outsourcing health and safety functions",
          "Focusing on marketing strategies",
          "Reviewing only financial risks",
        ],
        correctAnswer: "Continual improvement of the OHSMS based on audit findings and feedback"
      },
      {
        question: "What is the purpose of an internal audit in ISO 45001?",
        options: [
          "To evaluate financial compliance",
          "To assess whether the OHSMS conforms to the standard and is effectively implemented",
          "To review marketing strategies for safety programs",
          "To train employees on environmental management"
        ],
        correctAnswer: "To assess whether the OHSMS conforms to the standard and is effectively implemented"
      }
    ],
    iso22000: [
      {
        question: "What is the primary purpose of ISO 22000?",
        options: [
          "To improve financial performance",
          "To ensure food safety throughout the supply chain",
          "To enhance customer satisfaction",
          "To reduce production costs"
        ],
        correctAnswer: "To ensure food safety throughout the supply chain"
      },
      {
        question: "Which clause in ISO 22000 addresses internal audits",
        options: [
          "Clause 4.2",
          " Clause 7.5",
          " Clause 9.2",
          "Clause 10.3"
        ],
        correctAnswer: " Clause 9.2"
      },
      {
        question: "What is the role of the HACCP principles in ISO 22000?",
        options: [
          "To define marketing strategies",
          "To identify and control food safety hazards",
          "To monitor employee performance",
          "To document financial audits"
        ],
        correctAnswer: "Food Safety Management SystemTo identify and control food safety hazards"
      },
      {
        question: "What does Clause 4.1 in ISO 22000 address?",
        options: [
          "Training employees on food safety",
          "Understanding the organization and its context",
          "Analyzing market competition",
          "Reviewing customer complaints"
        ],
        correctAnswer: "Understanding the organization and its context"
      },
      {
        question: "What is required under Clause 7.1 for planning in ISO 22000?",
        options: [
          "Developing financial strategies",
          "Identifying actions to address risks and opportunities related to food safety",
          "Increasing marketing campaigns",
          "Outsourcing all food safety processes"
        ],
        correctAnswer: "Identifying actions to address risks and opportunities related to food safety"
      },
      {
        question: "What is the purpose of a food safety policy in ISO 22000?",
        options: [
          " To define quality assurance objectives",
          "To communicate the organization's commitment to food safety",
          "To outline marketing strategies",
          "To replace operational procedures"
        ],
        correctAnswer: " To communicate the organization's commitment to food safety"
      },
      {
        question: "What is a critical control point (CCP) in ISO 22000?",
        options: [
          "A step in the process where food quality is tested",
          "A step where control is essential to prevent or eliminate a food safety hazard",
          "A management review meeting",
          " A corrective action procedure"
        ],
        correctAnswer: "A step where control is essential to prevent or eliminate a food safety hazard"
      },
      {
        question: "What does ISO 22000 require for the verification of control measures?Which document outlines the scope of an organization's FSMS?",
        options: [
          "Verification must be conducted annually",
          "Control measures must be validated to ensure they are effective in managing hazards",
          "Results must only be shared with the top management",
          "No verification is needed if no hazards are found"
        ],
        correctAnswer: "Control measures must be validated to ensure they are effective in managing hazards"
      },
      {
        question: "What is the purpose of corrective actions in ISO 22000?",
        options: [
          "To address non-conformities and prevent their recurrence",
          "To document employee performance reviews",
          "To ensure customer satisfaction surveys are completed",
          "To adjust financial budgets"
        ],
        correctAnswer: "To address non-conformities and prevent their recurrence"
      },
      {
        question: "What is addressed under Clause 10.2 in ISO 22000?",
        options: [
          "Supplier performance reviews",
          "Continual improvement of the FSMS",
          "Employee performance evaluations",
          "Market research and analysis"
        ],
        correctAnswer: "Continual improvement of the FSMS"
      }
    ],
    iso27001: [
      {
        question: "What is the primary purpose of ISO/IEC 27001?",
        options: [
          "To reduce financial costs",
          "To manage and mitigate information security risks",
          "To improve marketing strategies",
          "To train employees on cybersecurity"
        ],
        correctAnswer: "To manage and mitigate information security risks"
      },
      {
        question: "Which clause in ISO/IEC 27001 focuses on internal audits?",
        options: [
          "Clause 6.2",
          "Clause 8.5",
          "Clause 9.2",
          "Clause 10.3"
        ],
        correctAnswer: "Clause 9.2"
      },
      {
        question: "What is the Annex A in ISO/IEC 27001 used for?",
        options: [
          "It provides mandatory auditing templates",
          "It lists reference controls for managing information security risks",
          "It outlines financial reporting standards",
          "It describes employee evaluation methods"
        ],
        correctAnswer: "It lists reference controls for managing information security risks"
      },
      {
        question: "What is a key responsibility of an internal auditor in ISO/IEC 27001?",
        options: [
          "To train the IT team on security measures",
          "To independently assess the ISMS and identify non-conformities",
          "To replace the management during crises",
          "To document marketing strategies"
        ],
        correctAnswer: "To independently assess the ISMS and identify non-conformities"
      },
      {
        question: "What is the purpose of the Statement of Applicability (SoA) in ISO/IEC 27001?",
        options: [
          "To list the organization's cybersecurity tool",
          "To document selected controls and their justification",
          "To describe employee training programs",
          "To analyze the company's financial goals"
        ],
        correctAnswer: "To document selected controls and their justification"
      },
      {
        question: "What does ISO/IEC 27001 require for addressing risks and opportunities?",
        options: [
          "Implementing a risk assessment process and defining actions to mitigate identified risks",
          " Focusing solely on financial risks",
          "Ignoring risks that are unlikely to occur",
          "Hiring external consultants for all risk-related activities"
        ],
        correctAnswer: "Implementing a risk assessment process and defining actions to mitigate identified risks"
      },
      {
        question: "What does Clause 8.1 in ISO/IEC 27001 address?",
        options: [
          "Resource management",
          "Operational planning and control of information security processes",
          "Financial performance reviews",
          "Marketing strategies"
        ],
        correctAnswer: "Operational planning and control of information security processes"
      },
      {
        question: "What is the purpose of corrective actions in ISO/IEC 27001?",
        options: [
          "To train employees on the ISMS",
          "To address non-conformities and prevent their recurrence",
          "To increase budget allocation for security tools",
          "To improve customer satisfaction surveys"
        ],
        correctAnswer: "To address non-conformities and prevent their recurrence"
      },
      {
        question: "What is the primary focus of Clause 10.2 in ISO/IEC 27001?",
        options: [
          "Employee performance reviews",
          "Continual improvement of the ISMS",
          "Supplier evaluations",
          "Marketing strategies for security services"
        ],
        correctAnswer: "Continual improvement of the ISMS"
      },
      {
        question: "What does the term information asset mean in the context of ISO/IEC 27001?",
        options: [
          "Physical resources like office furniture",
          "Any data or information valuable to the organization",
          "Employee performance reviews",
          "Financial budgets for IT projects"
        ],
        correctAnswer: "Any data or information valuable to the organization"
      }
    ],
    iso22301: [
      {
        question: "What is the primary purpose of ISO 22301?",
        options: [
          "To ensure financial stability",
          "To establish, implement, and maintain an effective Business Continuity Management System",
          "To enhance employee satisfaction",
          "To improve marketing strategies"
        ],
        correctAnswer: "To establish, implement, and maintain an effective Business Continuity Management System"
      },
      {
        question: "Which clause in ISO 22301 focuses on internal audits?",
        options: [
          "Clause 6.3",
          "Clause 8.5",
          "Clause 9.2",
          "Clause 10.3"
        ],
        correctAnswer: "Clause 9.2"
      },
      {
        question: "What is the purpose of the Business Impact Analysis (BIA) in ISO 22301?",
        options: [
          "To assess potential financial losses",
          "To identify and analyze the effects of disruption on critical activities",
          "To train employees on emergency procedures",
          "To develop marketing strategies for crises"
        ],
        correctAnswer: "To identify and analyze the effects of disruption on critical activities"
      },
      {
        question: "What does Clause 4.1 of ISO 22301 address?",
        options: [
          "Operational control",
          "Understanding the organization and its context",
          "Employee performance reviews",
          "Supplier evaluations"
        ],
        correctAnswer: "Understanding the organization and its context"
      },
      {
        question: "What is required under Clause 6.1 for planning in ISO 22301?",
        options: [
          " Creating a financial forecast",
          "Identifying risks and opportunities that affect the BCMS",
          "Hiring additional staff",
          "Outsourcing all IT operations"
        ],
        correctAnswer: "Identifying risks and opportunities that affect the BCMS"
      },
      {
        question: "What is the purpose of a Business Continuity Plan (BCP) in ISO 22301?",
        options: [
          "To train employees on organizational culture",
          "To establish documented procedures for responding to and recovering from disruptions",
          "To document marketing strategies",
          "To replace the risk management process"
        ],
        correctAnswer: "To establish documented procedures for responding to and recovering from disruptions"
      },
      {
        question: "What does ISO 22301 require for testing and exercising the BCMS?",
        options: [
          "Conducting tests only during disruptions",
          "Regularly testing and exercising plans to ensure effectiveness and identify improvements",
          "Performing tests only on financial data",
          "Skipping tests for well-documented plans"
        ],
        correctAnswer: "Regularly testing and exercising plans to ensure effectiveness and identify improvements"
      },
      {
        question: "What does the term recovery time objective (RTO) mean in ISO 22301?",
        options: [
          "The maximum acceptable time to restore a critical activity after disruption",
          "The total time taken to test a system",
          "The duration of employee training sessions",
          "The time spent on supplier evaluations"
        ],
        correctAnswer: "The maximum acceptable time to restore a critical activity after disruption"
      },
      {
        question: "What is the significance of Clause 10.2 in ISO 22301?",
        options: [
          "It addresses continual improvement of the BCMS",
          "It specifies financial compliance requirements",
          "It defines employee evaluation procedures",
          "It focuses on marketing strategies during a crisis"
        ],
        correctAnswer: "It addresses continual improvement of the BCMS"
      },
      {
        question: "What is a key responsibility of internal auditors under ISO 22301?",
        options: [
          "To train employees on business continuity",
          "To independently assess compliance with the BCMS requirements and identify non-conformities",
          "To create financial reports",
          " To replace the management team during audits"
        ],
        correctAnswer: "To independently assess compliance with the BCMS requirements and identify non-conformities"
      }
    ],
    iso20000: [
      {
        question: "What is the primary purpose of ISO/IEC 20000-1?",
        options: [
          "To improve financial performance",
          "To provide a framework for delivering consistent IT services",
          "To enhance marketing strategies",
          "To reduce the cost of technology"
        ],
        correctAnswer: "To provide a framework for delivering consistent IT services"
      },
      {
        question: "Which clause of ISO/IEC 20000-1 focuses on internal audits?",
        options: [
          "Clause 4.3",
          "Clause 8.7",
          "Clause 9.2",
          " Clause 10.3"
        ],
        correctAnswer: " Clause 9.2"
      },
      {
        question: "What does the term Service Management System (SMS) refer to in ISO/IEC 20000-1?",
        options: [
          "A system used for employee performance evaluations",
          "A framework to manage and deliver IT services effectively",
          "A tool for monitoring financial transactions",
          "A process for managing external suppliers"
        ],
        correctAnswer: "A framework to manage and deliver IT services effectively"
      },
      {
        question: "What is the role of an internal auditor in ISO/IEC 20000-1 compliance?",
        options: [
          " To implement new services",
          "To independently evaluate the SMS and identify non-conformities",
          "To replace the management team during audits",
          "To train external suppliers"
        ],
        correctAnswer: "Financial To independently evaluate the SMS and identify non-conformities"
      },
      {
        question: "What is the purpose of the Service Management Policy?",
        options: [
          "To document employee responsibilities",
          "To define the organization's commitment to meeting IT service objectives",
          " To establish financial goals for the IT department",
          "To train employees on new technologies"
        ],
        correctAnswer: "To define the organization's commitment to meeting IT service objectives"
      },
      {
        question: "What is required to be documented during an internal audit under ISO/IEC 20000-1?",
        options: [
          "Audit plans, findings, and evidence of non-conformities",
          "IT hardware and software costs",
          "Customer satisfaction surveys",
          "Financial performance metrics"
        ],
        correctAnswer: "Audit plans, findings, and evidence of non-conformities"
      },
      {
        question: "What does ISO/IEC 20000-1 require for managing risks to IT services?",
        options: [
          " Establishing a risk register and taking actions to mitigate significant risks",
          "Conducting risk reviews every ten years",
          "Ignoring risks with low probability",
          "Relying solely on external consultants for risk management"
        ],
        correctAnswer: " Establishing a risk register and taking actions to mitigate significant risks"
      },
      {
        question: "What is the significance of Clause 8.1 in ISO/IEC 20000-1?",
        options: [
          "It defines how financial audits should be conducted",
          "It focuses on operational planning and control of IT services",
          "It outlines recruitment strategies for IT staff",
          "It describes marketing strategies for IT services"
        ],
        correctAnswer: "It focuses on operational planning and control of IT services"
      },
      {
        question: "How does ISO/IEC 20000-1 define continual improvement?",
        options: [
          " As a one-time effort to meet certification requirements",
          "As an ongoing effort to enhance IT service processes and SMS effectiveness",
          "As a strategy for financial improvement",
          "As a quarterly review of employee performancece"
        ],
        correctAnswer: "As an ongoing effort to enhance IT service processes and SMS effectiveness"
      },
      {
        question: "What does Clause 7.4 of ISO/IEC 20000-1 address?",
        options: [
          "Financial reporting requirements",
          "Resource management, including personnel, infrastructure, and tools",
          " Customer satisfaction surveys",
          "Training external vendors"
        ],
        correctAnswer: "Resource management, including personnel, infrastructure, and tools"
      }
    ],
    iso27701: [
      {
        question: "What is the primary purpose of ISO/IEC 27701?",
        options: [
          "To enhance marketing strategies",
          "To provide guidelines for managing privacy and protecting personal data",
          "To improve employee training programs",
          "To reduce organizational costs"
        ],
        correctAnswer: "To provide guidelines for managing privacy and protecting personal data"
      },
      {
        question: "ISO/IEC 27701 is an extension of which standard?",
        options: [
          "ISO/IEC 22301",
          "ISO/IEC 27001",
          "ISO 9001",
          "ISO/IEC 20000-1"
        ],
        correctAnswer: "ISO/IEC 27001"
      },
      {
        question: "What is the main focus of Clause 5 in ISO/IEC 27701?",
        options: [
          "Organizational roles and responsibilities for privacy",
          "Marketing strategies for privacy programs",
          "Financial planning for privacy compliance",
          "Employee performance reviews"
        ],
        correctAnswer: "Organizational roles and responsibilities for privacy"
      },
      {
        question: "Which key document does ISO/IEC 27701 require for personal data processing activities?",
        options: [
          "Marketing strategy reports",
          "Privacy Impact Assessment (PIA)",
          "Annual financial statements",
          "Employee evaluation forms"
        ],
        correctAnswer: "Privacy Impact Assessment (PIA)"
      },
      {
        question: "What role does a Data Protection Officer (DPO) play in ISO/IEC 27701?",
        options: [
          "Ensures compliance with privacy laws and standards",
          "Oversees the organization's marketing strategies",
          "Manages employee engagement programs",
          "To increase market shareLeads the organization’s financial audits"
        ],
        correctAnswer: "Ensures compliance with privacy laws and standards"
      },
      {
        question: "What does ISO/IEC 27701 require for third-party relationships?",
        options: [
          "Ignoring third-party data practices",
          "Establishing contracts and monitoring privacy practices of third parties",
          "Outsourcing all privacy responsibilities to third parties",
          "Allowing third parties to define their own privacy policies"
        ],
        correctAnswer: "Establishing contracts and monitoring privacy practices of third parties"
      },
      {
        question: "What is the purpose of the Record of Processing Activities (ROPA) in ISO/IEC 27701?",
        options: [
          "To track financial transactions",
          "To document how personal data is processed and managed",
          "To evaluate employee performance",
          "To replace the Statement of Applicability (SoA)"
        ],
        correctAnswer: "To document how personal data is processed and managed"
      },
      {
        question: "Which clause in ISO/IEC 27701 focuses on internal audits?",
        options: [
          "Clause 9.2",
          "Clause 6.3",
          "Clause 10.3",
          "Clause 5.1"
        ],
        correctAnswer: "Clause 9.2"
      },
      {
        question: "What is the main goal of Clause 10 in ISO/IEC 27701?",
        options: [
          "To outline marketing strategies",
          "To promote continual improvement in privacy management",
          "To review employee performance",
          "To ensure financial compliance"
        ],
        correctAnswer: "To promote continual improvement in privacy management"
      },
      {
        question: "What is the purpose of a Privacy Information Management System (PIMS)?",
        options: [
          "To improve financial performance",
          "To manage risks associated with personal data and comply with privacy regulations",
          "To enhance marketing strategies",
          "To streamline IT infrastructure"
        ],
        correctAnswer: "To manage risks associated with personal data and comply with privacy regulations"
      }
    ],
    iso13485: [
      {
        question: "What is the primary purpose of ISO 13485?",
        options: [
          "To manage environmental risks",
          "To ensure safety and effectiveness of medical devices",
          "To improve employee satisfaction",
          "To reduce operational costs"
        ],
        correctAnswer: "To ensure safety and effectiveness of medical devices"
      },
      {
        question: "Which clause in ISO 13485 addresses internal audits?",
        options: [
          "Clause 6.3",
          "Clause 7.2",
          "Clause 8.2.4",
          "Clause 9.1"
        ],
        correctAnswer: "Clause 8.2.4"
      },
      {
        question: "What is the key focus of risk management in ISO 13485?",
        options: [
          " Reducing financial losses",
          "Ensuring the safety of medical devices throughout their lifecycle",
          "Increasing customer satisfaction",
          "Enhancing marketing efforts"
        ],
        correctAnswer: "Ensuring the safety of medical devices throughout their lifecycle"
      },
      {
        question: "What is required to be documented during an internal audit?",
        options: [
          "The audit plan and results, including any non-conformities",
          "Employee performance reviews",
          "Financial outcomes",
          " A product that is in high demand"
        ],
        correctAnswer: "The audit plan and results, including any non-conformities"
      },
      {
        question: "What does ISO 13485 emphasize for handling non-conformities?",
        options: [
          "Punishment for the responsible personnel",
          "Preventive action to avoid potential issues",
          "Corrective action to address and eliminate the root cause",
          "Ignoring minor issues to save time"
        ],
        correctAnswer: "Corrective action to address and eliminate the root cause"
      },
      {
        question: "Which of the following is a requirement under Clause 7.3 (Design and Development)?",
        options: [
          "Employee benefits programs",
          "Verification and validation of the medical device design",
          "Customer feedback surveys",
          "Internal audit scheduling"
        ],
        correctAnswer: "Verification and validation of the medical device design"
      },
      {
        question: "What is the role of an internal auditor in ISO 13485 compliance?",
        options: [
          "To implement changes to processes",
          "To independently evaluate the QMS and report findings",
          "To train employees on the standard",
          "To certify the organization"
        ],
        correctAnswer: "To independently evaluate the QMS and report findings"
      },
      {
        question: "What is a critical difference between ISO 9001 and ISO 13485?",
        options: [
          "ISO 13485 focuses on medical devices and includes regulatory requirements",
          "ISO 13485 is only for small businesses",
          " ISO 13485 has no risk management requirements",
          "ISO 13485 focuses exclusively on financial performance"
        ],
        correctAnswer: "SO 13485 focuses on medical devices and includes regulatory requirements"
      },
      {
        question: "What is the purpose of supplier controls in ISO 13485?",
        options: [
          "To reduce costs associated with procurement",
          "To ensure purchased products and services meet specified requirements",
          "To eliminate all external suppliers",
          "To enhance supplier relationships"
        ],
        correctAnswer: "To ensure purchased products and services meet specified requirements"
      },
      {
        question: "What is required under Clause 4.2 regarding documentation?",
        options: [
          "Financial statements",
          "Establishing and maintaining a documented Quality Management System (QMS)",
          "Monthly employee performance reviews",
          "Marketing and sales strategies"
        ],
        correctAnswer: "Establishing and maintaining a documented Quality Management System (QMS)"
      }
    ],
    iso17025: [
      {
        question: "What is the main purpose of ISO/IEC 17025?",
        options: [
          "To enhance employee satisfaction",
          "To ensure laboratories demonstrate competence and generate valid results",
          "To improve financial performance",
          "To reduce the cost of operations",
        ],
        correctAnswer: "To ensure laboratories demonstrate competence and generate valid results",
      },
      {
        question: "Which clause in ISO/IEC 17025 addresses internal audits?",
        options: [
          "Clause 6.2",
          "Clause 7.1",
          "Clause 8.8",
          "Clause 10.2",
        ],
        correctAnswer: "Clause 8.8",
      },
      {
        question: "What is the significance of impartiality in ISO/IEC 17025?",
        options: [
          "To ensure the laboratory remains independent and avoids conflicts of interest",
          "To improve financial stability",
          "To reduce workload on staff",
          "To comply with financial regulations",
        ],
        correctAnswer: "To ensure the laboratory remains independent and avoids conflicts of interest",
      },
      {
        question: "What is required under Clause 6.4 for laboratory equipment?",
        options: [
          "Equipment should be purchased from the lowest bidder",
          "Equipment must be calibrated, maintained, and fit for its intended use",
          "Equipment records should be kept for at least one year",
          "Only equipment costing above a certain threshold needs documentation",
        ],
        correctAnswer: "Equipment must be calibrated, maintained, and fit for its intended use",
      },
      {
        question: "What does the term method validation mean in ISO/IEC 17025?",
        options: [
          "Ensuring a method complies with financial guidelines",
          "Confirming a method produces reliable and consistent results for its intended use",
          "Testing a new software program",
          "Comparing results with competitor laboratories",
        ],
        correctAnswer: "Confirming a method produces reliable and consistent results for its intended use",
      },
      {
        question: "What is required to be documented during an internal audit under ISO/IEC 17025?",
        options: [
          "Audit plans, non-conformities, and corrective actions",
          "Employee performance metrics",
          "Financial performance reviews",
          "Daily laboratory schedules",
        ],
        correctAnswer: "Audit plans, non-conformities, and corrective actions",
      },
      {
        question: "What does ISO/IEC 17025 require under Clause 7.8 for reporting results?",
        options: [
          "Reports must include only numerical results",
          "Results must be clear, accurate, and traceable to the method used",
          "Reports must follow customer-specified formats exclusively",
          " All results must be stored in hard copies only",
        ],
        correctAnswer: "Results must be clear, accurate, and traceable to the method used",
      },
      {
        question: "What is the primary purpose of corrective actions in ISO/IEC 17025?",
        options: [
          "To punish staff responsible for errors",
          "To identify and eliminate the root cause of non-conformities",
          "To delay customer reporting until corrections are completed",
          "To update financial documents",
        ],
        correctAnswer: "To identify and eliminate the root cause of non-conformities",
      },
      {
        question: "What is the purpose of proficiency testing in a laboratory?",
        options: [
          "To assess the competence of laboratory personnel and ensure accuracy of results",
          " To train new employees",
          "To meet financial compliance requirements",
          "To compare performance with other industries",
        ],
        correctAnswer: "To assess the competence of laboratory personnel and ensure accuracy of results",
      },
      {
        question: "What is required under Clause 8.5 for continual improvement?",
        options: [
          "Documented reviews of the EMS every five years",
          "Regular analysis of feedback, audit results, and performance data to enhance the QMS",
          "Engaging third-party consultants for improvement plans",
          "Revising equipment procurement policies annually",
        ],
        correctAnswer: "Regular analysis of feedback, audit results, and performance data to enhance the QMS",
      },

    ],
    iso50001: [
      {
        question: "What is the primary purpose of ISO 50001?",
        options: [
          "To improve financial performance",
          "To establish a framework for managing energy performance",
          "To enhance marketing strategies",
          "To train employees in quality management",
        ],
        correctAnswer: "To establish a framework for managing energy performance",
      },
      {
        question: "Which clause in ISO 50001 focuses on internal audits?",
        options: [
          "Clause 6.2",
          "Clause 9.2",
          "Clause 8.5",
          "Clause 10.3",
        ],
        correctAnswer: "Clause 9.2",
      },
      {
        question: "What is the main objective of Clause 4 in ISO 50001?",
        options: [
          "Identifying financial risks",
          "Understanding the organization and its context related to energy use",
          "Managing external suppliers",
          "Conducting employee training",
        ],
        correctAnswer: "Understanding the organization and its context related to energy use",
      },
      {
        question:"What is required in the energy planning process under Clause 6.1 of ISO 50001?",
        options: [
          "Identifying energy performance improvements and determining energy objectives",
          "Developing marketing strategies",
          " Reviewing employee performance",
          "Focusing on financial audits",
        ],
        correctAnswer: "Identifying energy performance improvements and determining energy objectives",
      },
      {
        question: "What is the purpose of the Energy Review in ISO 50001?",
        options: [
          "To analyze financial performance",
          "To assess and evaluate the energy performance, consumption, and use of energy within the organization",
          "To determine employee bonuses",
          "To evaluate the marketing strategy",
        ],
        correctAnswer: "To assess and evaluate the energy performance, consumption, and use of energy within the organization",
      },
      {
        question: "What does Clause 8.1 of ISO 50001 require for operational control?",
        options: [
          "Developing financial reports",
          "Establishing procedures and controls to ensure energy performance targets are met",
          "Conducting employee performance reviews",
          "Training employees on energy-efficient tools",
        ],
        correctAnswer: "Establishing procedures and controls to ensure energy performance targets are met",
      },
      {
        question: "What is the role of leadership in ISO 50001?",
        options: [
          "To delegate all energy management responsibilities to external contractors",
          "To provide direction, support, and resources for the energy management system",
          "To focus solely on financial audits",
          "To outsource energy management tasks",
        ],
        correctAnswer: "To provide direction, support, and resources for the energy management system",
      },
      {
        question: "What is the purpose of the energy objectives and targets in ISO 50001?",
        options: [
          "To reduce operational costs unrelated to energy use",
          "To provide specific, measurable goals for improving energy performance",
          "To track employee performance",
          "To develop marketing strategies",
        ],
        correctAnswer: "To provide specific, measurable goals for improving energy performance",
      },
      {
        question: "What is required under Clause 10 in ISO 50001?",
        options: [
          "Continual improvement of the EnMS based on audit results, performance reviews, and corrective actions",
          "Focusing only on energy cost reduction",
          " Reviewing only external suppliers' energy performance",
          "Evaluating the quality of employee work in energy management",
        ],
        correctAnswer: "Continual improvement of the EnMS based on audit results, performance reviews, and corrective actions",
      },
      {
        question: "What is the purpose of an internal audit in ISO 50001?",
        options: [
          "To evaluate financial compliance",
          "To assess whether the EnMS conforms to the standard and is effectively implemented",
          "To review marketing strategies for energy programs",
          "To assess employee satisfaction",
        ],
        correctAnswer: "To assess whether the EnMS conforms to the standard and is effectively implemented",
      },

    ],
    gmp: [
      {
        question: "What is the primary purpose of Good Manufacturing Practices (GMP)?",
        options: [
          "To improve marketing strategies",
          "To ensure that products are consistently produced and controlled according to quality standards",
          "To reduce employee turnover",
          "To optimize energy use in manufacturing",
        ],
        correctAnswer: "To ensure that products are consistently produced and controlled according to quality standards",
      },
      {
        question: "Which of the following is a requirement under GMP for personnel in a manufacturing facility?",
        options: [
          "They must be trained and qualified for the tasks they perform",
          "They should be able to produce marketing materials",
          "They must not use personal protective equipment",
          "They are not required to have documented training records",
        ],
        correctAnswer: "They must be trained and qualified for the tasks they perform",
      },
      {
        question: "What is the purpose of a batch production record in GMP?",
        options: [
          "To document employee attendance",
          "To ensure traceability and consistency in production for each batch",
          "To record marketing results",
          "To document financial transactions",
        ],
        correctAnswer: "To ensure traceability and consistency in production for each batch",
      },
      {
        question: "What does GMP require regarding the control of raw materials?",
        options: [
          "Raw materials should not be inspected",
          "Raw materials must be properly identified, tested, and stored before use",
          "Raw materials can be used without inspection if they are readily available",
          "Only a few key raw materials need to be controlled",
        ],
        correctAnswer: "Raw materials must be properly identified, tested, and stored before use",
      },
      {
        question: "What is the role of hygiene and sanitation in GMP?",
        options: [
          "Hygiene is not important as long as the product is safe",
          "Only the facility's exterior needs to be sanitized",
          "Personal hygiene and facility sanitation must be strictly controlled to prevent contamination",
          "Hygiene is only required for food products",
        ],
        correctAnswer: "Personal hygiene and facility sanitation must be strictly controlled to prevent contamination",
      },
      {
        question: "What does GMP require regarding equipment maintenance?",
        options: [
          "Equipment should only be maintained when it breaks down",
          "Equipment must be properly maintained and cleaned to ensure safe and effective operation",
          "Equipment maintenance is not necessary if it is not directly related to product quality",
          "Equipment maintenance should be handled only by external vendors",
        ],
        correctAnswer: "Equipment must be properly maintained and cleaned to ensure safe and effective operation",
      },
      {
        question: "Which of the following is a critical part of GMP in the production environment?",
        options: [
          "Maintaining consistent temperature, humidity, and cleanliness to avoid contamination",
          "Avoiding employee training",
          "Keeping production speed high, regardless of quality",
          "Ignoring raw material quality",
        ],
        correctAnswer: "Maintaining consistent temperature, humidity, and cleanliness to avoid contamination",
      },
      {
        question: "Under GMP, what is required to ensure product quality control?",
        options: [
          "Quality control is optional if the product is in high demand",
          "Products must be checked and tested throughout production to ensure they meet specifications",
          "Only the final product needs to be tested",
          "The product must be sent to external labs for testing only",
        ],
        correctAnswer: "Products must be checked and tested throughout production to ensure they meet specifications",
      },
      {
        question: "What should be done if a GMP audit identifies a non-compliance issue?",
        options: [
          "Ignore it if it doesn’t affect product quality",
          "Take corrective actions to address the non-compliance and prevent recurrence",
          "Report the issue but take no immediate action",
          "Only notify top management without any corrective steps",
        ],
        correctAnswer: "Take corrective actions to address the non-compliance and prevent recurrence",
      },
      {
        question: "How should GMP documentation and records be handled?",
        options: [
          "Documentation can be discarded after a year",
          "Records must be accurately maintained, readily available, and retrievable for auditing and review",
          "Documents can be kept in any format, including verbal records",
          "Records are not needed for materials used in production",
        ],
        correctAnswer: "Records must be accurately maintained, readily available, and retrievable for auditing and review",
      },
      
    ],
    haccp: [
      {
        question: "What is the primary goal of HACCP?",
        options: [
          "To improve production efficiency",
          "To identify, evaluate, and control food safety hazards",
          "To manage inventory more effectively",
          "To reduce the need for employee training",
        ],
        correctAnswer: "To identify, evaluate, and control food safety hazards",
      },
      {
        question: "Which of the following is a key principle of HACCP?",
        options: [
          "Implementing regular marketing audits",
          "Establishing critical control points to monitor and control food safety hazards",
          "Focusing solely on the final product",
          "Ignoring hazards that are not easily measurable",
        ],
        correctAnswer: "Establishing critical control points to monitor and control food safety hazards",
      },
      {
        question: "What does the acronym HACCP stand for?",
        options: [
          "Hazard Analysis and Cost Control Points",
          "Hazard Analysis and Critical Control Points",
          "Hazard Assessment and Control Procedures",
          "Hazard Assessment and Critical Control Procedures",
        ],
        correctAnswer: "Hazard Analysis and Critical Control Points",
      },
      {
        question: "What is the first step in the HACCP system?",
        options: [
          "Establishing critical control points",
          "Conducting a hazard analysis",
          "Establishing corrective actions",
          "Preparing the final product",
        ],
        correctAnswer: "Conducting a hazard analysis",
      },
      {
        question: "What is a critical control point (CCP)?",
        options: [
          "A point in the process where hazards can be eliminated, reduced, or prevented",
          "A step in production that does not affect food safety",
          "A non-critical point that does not require monitoring",
          "A place for storing ingredients",
        ],
        correctAnswer: "A point in the process where hazards can be eliminated, reduced, or prevented",
      },
      {
        question: "What is a common hazard that HACCP aims to control in food production?",
        options: [
          "Financial risks",
          "Physical, chemical, and biological food safety hazards",
          "Employee turnover",
          "Marketing failures",
        ],
        correctAnswer: "Physical, chemical, and biological food safety hazards",
      },
      {
        question: "What is the purpose of establishing critical limits for each CCP in the HACCP system?",
        options: [
          "To ensure that the production process meets the expected timeline",
          "To define the acceptable range of conditions that must be met to control hazards",
          "To establish product pricing",
          "To reduce the cost of raw materials",
        ],
        correctAnswer: "To define the acceptable range of conditions that must be met to control hazards",
      },
      {
        question: "How should monitoring activities be conducted for each critical control point (CCP)?",
        options: [
          "Monitoring should be conducted once a month",
          "Monitoring should be continuous or frequent enough to ensure CCPs remain under control",
          "Monitoring is only necessary during food product shipping",
          "Monitoring is not required unless a safety issue arises",
        ],
        correctAnswer: "Monitoring should be continuous or frequent enough to ensure CCPs remain under control",
      },
      {
        question: "What action should be taken if a critical limit for a CCP is not met?",
        options: [
          "No action is necessary if the issue is small",
          "Corrective actions must be implemented to bring the process back into control and prevent unsafe food production",
          "The product is automatically considered safe and should be shipped",
          "A report should be written, but no immediate actions are necessary",
        ],
        correctAnswer: "Corrective actions must be implemented to bring the process back into control and prevent unsafe food production",
      },
      {
        question: "Which of the following is required for an effective HACCP system?",
        options: [
          "A written HACCP plan that includes all the principles and steps",
          "A system that does not require record-keeping",
          "A focus on financial profitability rather than food safety",
          "Only visual inspections of the food production process",
        ],
        correctAnswer: "A written HACCP plan that includes all the principles and steps",
      },
      
    ],
    soc2: [
      {
        question: "What is the primary purpose of a SOC 2 audit?",
        options: [
          "To ensure compliance with tax regulations",
          "To assess how well a company’s information systems protect customer data",
          "To evaluate employee performance",
          "To check financial reporting accuracy",
        ],
        correctAnswer:
          "To assess how well a company’s information systems protect customer data",
      },
      {
        question:
          "Which of the following Trust Service Criteria are evaluated during a SOC 2 audit?",
        options: [
          "Security, Availability, Confidentiality, Processing Integrity, Privacy",
          "Financial Integrity, Legal Compliance, Privacy",
          "Cost Efficiency, Time Management, Confidentiality",
          "Product Performance, System Availability, Network Performance",
        ],
        correctAnswer:
          "Security, Availability, Confidentiality, Processing Integrity, Privacy",
      },
      {
        question:
          "What does the ‘Security’ Trust Service Criteria in SOC 2 focus on?",
        options: [
          "Ensuring the availability of services",
          "Protecting sensitive data from unauthorized access, disclosure, or damage",
          "Ensuring the confidentiality of personal data",
          "Ensuring that systems perform as expected",
        ],
        correctAnswer:
          "Protecting sensitive data from unauthorized access, disclosure, or damage",
      },
      {
        question:
          "What is the key difference between SOC 2 Type I and Type II reports?",
        options: [
          "Type I evaluates the effectiveness of controls over time, while Type II evaluates the design of controls at a specific point in time",
          "Type I focuses on financial controls, while Type II focuses on security controls",
          "Type I evaluates the design of controls, while Type II evaluates the effectiveness of controls over time",
          "Type II is for smaller organizations, while Type I is for larger organizations",
        ],
        correctAnswer:
          "Type I evaluates the design of controls, while Type II evaluates the effectiveness of controls over time",
      },
      {
        question: "How often should a SOC 2 audit be conducted?",
        options: [
          "Annually, or as determined by the organization’s contractual obligations or business needs",
          "Every five years",
          "Only when requested by regulators",
          "Only when an organization is expanding its market",
        ],
        correctAnswer:
          "Annually, or as determined by the organization’s contractual obligations or business needs",
      },
      {
        question:
          "What is an example of a control that could help meet the 'Processing Integrity' criteria in a SOC 2 audit?",
        options: [
          "Implementing data encryption for transmission",
          "Installing firewalls to prevent unauthorized access",
          "Implementing automated error checking in transaction processing systems",
          "Limiting access to sensitive data",
        ],
        correctAnswer:
          "Implementing automated error checking in transaction processing systems",
      },
      {
        question:
          "Which of the following is a responsibility of the internal auditor during a SOC 2 audit?",
        options: [
          "Preparing the final audit report",
          "Implementing security measures",
          "Testing and evaluating the design and effectiveness of controls against the Trust Service Criteria",
          "Managing external relationships with clients",
        ],
        correctAnswer:
          "Testing and evaluating the design and effectiveness of controls against",
      },
      {
        question:
          "What is the focus of the 'Privacy' Trust Service Criteria in SOC 2?",
        options: ["Security", "Privacy", "Availability", "Confidentiality"],
        correctAnswer: "Availability",
      },
      {
        question:
          "How often should SOC 2 audits be conducted to ensure ongoing compliance?",
        options: [
          "Protecting the integrity and confidentiality of customer data",
          "Managing access controls for data centers",
          "Safeguarding personal information in accordance with relevant privacy laws and regulations",
          "Ensuring the security of data transmissions",
        ],
        correctAnswer:
          "Safeguarding personal information in accordance with relevant privacy laws and regulations",
      },
      {
        question: "What is typically included in a SOC 2 audit report?",
        options: [
          "A statement of compliance with government regulations",
          "An assessment of the company’s financial performance",
          "A description of the organization’s system, controls in place, and an auditor’s opinion on their design or operational effectiveness",
          "A list of customers and their feedback on services",
        ],
        correctAnswer:
          "A description of the organization’s system, controls in place, and an auditor’s opinion on their design or operational effectiveness",
      },
    ],
  };