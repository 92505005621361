import React, { useState } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Home from "../Components/Home";
import Module1 from "../Components/Module1";
import Module2 from "../Components/Module2";
import Module3 from "../Components/Module3";
import Module1Quiz from "../Components/Module1Quiz";
import Module2Quiz from "../Components/Module2Quiz";
import Module3Quiz from "../Components/Module3Quiz";
import Register from "../Components/Register";


const AllRoutes = () => {
  return (
    <Routes>
      <Route path="/home" element={ <><Home /></>} />
      <Route path="/register" element={ <><Register /></>} />
      <Route path="*" element={<Navigate to="/register" />} />
      <Route path="/module1" element={ <><Module1 /></>} />
      <Route path="/module2" element={ <><Module2 /></>} />
      <Route path="/module3" element={ <><Module3 /></>} />
      <Route path="/quiz/module1/:certification" element={<Module1Quiz />} />
      <Route path="/quiz/module2/:certification" element={<Module2Quiz />} />
      <Route path="/quiz/module3/:certification" element={<Module3Quiz />} />
    </Routes>
  );
};

export default AllRoutes;
