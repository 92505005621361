//Question Data

export const questionsBank = {
    iso9001: [
      {
        question: "What is the primary objective of ISO 9001?",
        options: [
          "To ensure environmental compliance",
          "To improve financial performance",
          "To ensure consistent quality in products and services",
          "To manage occupational health and safety",
        ],
        correctAnswer: "To ensure consistent quality in products and services",
      },
      {
        question: "What does PDCA stand for in ISO 9001?",
        options: [
          "Plan, Do, Control, Act",
          "Plan, Develop, Check, Act",
          "Prepare, Design, Control, Adjust",
          "Plan, Do, Check, Act",
        ],
        correctAnswer: "Plan, Do, Check, Act",
      },
      {
        question: "Which clause of ISO 9001 focuses on Leadership?",
        options: ["Clause 4", "Clause 5", "Clause 6", "Clause 7"],
        correctAnswer: "Clause 5",
      },
      {
        question: "Which of the following is NOT a core principle of ISO 9001?",
        options: [
          "Customer focus",
          "Process approach",
          "Financial control",
          "Improvement",
        ],
        correctAnswer: "Financial control",
      },
      {
        question: "What is a nonconformity according to ISO 9001?",
        options: [
          "A deviation from specified requirements",
          "An opportunity for improvement",
          "A financial discrepancy",
          "A successful audit outcome",
        ],
        correctAnswer: "A deviation from specified requirements",
      },
      {
        question:
          "Who is responsible for ensuring resources are available for the Quality Management System (QMS)?",
        options: [
          "Middle management",
          "Quality department",
          "Top management",
          "External auditors",
        ],
        correctAnswer: "Top management",
      },
      {
        question:
          "Which document outlines the scope of an organization's Quality Management System?",
        options: [
          "Quality Manual",
          "Financial Report",
          "Environmental Policy",
          "Employee Handbook",
        ],
        correctAnswer: "Quality Manual",
      },
      {
        question: "In ISO 9001, what is meant by context of the organization?",
        options: [
          "The internal and external factors impacting the organization",
          "The company's financial status",
          "The hierarchical structure of the organization",
          "The geographic location of the company",
        ],
        correctAnswer:
          "The internal and external factors impacting the organization",
      },
      {
        question: "What role do internal audits play in ISO 9001?",
        options: [
          "They are optional and conducted at the discretion of the company",
          "They are performed only by external auditors",
          "They help ensure the QMS is effectively implemented and maintained",
          "They focus primarily on financial performance",
        ],
        correctAnswer:
          "They help ensure the QMS is effectively implemented and maintained",
      },
      {
        question:
          "How often should the management review the Quality Management System?",
        options: ["Monthly", "Quarterly", "Annually", "As needed"],
        correctAnswer: "Annually",
      },
    ],
    iso14001: [
      {
        question: "What is the primary focus of ISO 14001?",
        options: [
          " Quality Management",
          "Environmental Management",
          "Information Security",
          "Occupational Health and Safety",
        ],
        correctAnswer: "Environmental management",
      },
      {
        question: "Which clause of ISO 14001 deals with Environmental Aspects?",
        options: ["Clause 4.1", "Clause 6.1.2", "Clause 7.5", " Clause 8.1"],
        correctAnswer: "Clause 6.1.2",
      },
      {
        question: "What does EMS stand for in the context of ISO 14001?",
        options: [
          " Environmental Management Standard",
          " Energy Management System",
          "Environmental Monitoring System",
          "Environmental Management System",
        ],
        correctAnswer: "Environmental Management System",
      },
      {
        question:
          "Which of the following is NOT a core principle of ISO 14001?",
        options: [
          " Environmental performance improvement",
          " Compliance with legal requirements",
          " Financial performance improvement",
          "Pollution prevention",
        ],
        correctAnswer: " Financial performance improvement",
      },
      {
        question: "What is an environmental aspect according to ISO 14001?",
        options: [
          "An element of an organization's activities that can interact with the environment",
          "A financial asset of the organization",
          "A part of the organization's marketing strategy",
          "A product specification",
        ],
        correctAnswer:
          "An element of an organization's activities that can interact with the environment",
      },
      {
        question:
          "Who is responsible for the implementation of an EMS according to ISO 14001?",
        options: [
          "Middle management",
          "The quality department",
          "Top management",
          "External auditors",
        ],
        correctAnswer: "Top management",
      },
      {
        question:
          "What is the purpose of internal audits according to ISO 14001?",
        options: [
          " To improve financial performance",
          "To assess compliance with the environmental management system",
          "To develop new products",
          " To increase market share",
        ],
        correctAnswer:
          "To assess compliance with the environmental management system",
      },
      {
        question: "Which document outlines the scope of an organization's EMS?",
        options: [
          " Quality Manual",
          "Environmental Policy",
          "Financial Report",
          "Employee Handbook",
        ],
        correctAnswer: "Environmental Policy",
      },
      {
        question:
          "What is the significance of an environmental policy according to ISO 14001?",
        options: [
          " It demonstrates the organization's commitment to environmental management",
          "It outlines the financial strategies of the organization",
          "It specifies the marketing strategies of the organization",
          "It details the human resource policies",
        ],
        correctAnswer:
          "It demonstrates the organization's commitment to environmental management",
      },
      {
        question:
          "How often should the effectiveness of the EMS be reviewed according to ISO 14001?",
        options: ["Daily", "Monthly", "Annually", "As needed"],
        correctAnswer: "Annually",
      },
    ],
    iso45001: [
      {
        question: "What is the primary focus of ISO 45001?",
        options: [
          "Quality Management",
          "Environmental Management",
          "Occupational Health and Safety Management",
          "Information Security Management",
        ],
        correctAnswer: "Occupational Health and Safety Management",
      },
      {
        question: "Which clause of ISO 45001 deals with Leadership and Worker Participation?",
        options: [
          "Clause 4",
          "Clause 5",
          "Clause 6",
          " Clause 7",
        ],
        correctAnswer: "Clause 5",
      },
      {
        question: "What does OHSMS stand for in the context of ISO 45001?",
        options: [
          "Occupational Health and Safety Management System",
          " Occupational Health and Safety Monitoring Standard",
          "Organizational Health and Safety Management Scheme",
          "Occupational Hazard Safety Management System",
        ],
        correctAnswer: " Occupational Health and Safety Management System",
      },
      {
        question: "Which of the following is NOT a core component of ISO 45001?",
        options: [
          "Risk Assessment",
          "Financial Auditing",
          "Worker Participation",
          "Incident Investigation",
        ],
        correctAnswer: "Financial Auditing"
      },
      {
        question: "What is the purpose of a hazard identification process in ISO 45001?",
        options: [
          "To evaluate financial performance",
          "To identify and assess workplace hazards",
          "To improve employee satisfaction",
          "To increase market share",
        ],
        correctAnswer: "To identify and assess workplace hazards"
      },
      {
        question: "Who is responsible for ensuring the OHSMS is effectively implemented and maintained according to ISO 45001?",
        options: [
          "Middle management",
          "Health and safety department",
          "Top management",
          "External auditors",
        ],
        correctAnswer: "Top management"
      },
      {
        question: "What is a risk according to ISO 45001?",
        options: [
          "Any condition that ensures employee safety",
          "Any source of potential harm or damage",
          "Any financial transaction error",
          "Any planned marketing strategy",
        ],
        correctAnswer: 'Any source of potential harm or damage'
      },
      {
        question: "Which document outlines the scope of an organization's OHSMS?",
        options: [
          "Quality Manual",
          "Occupational Health and Safety Policy",
          "Financial Report",
          "Employee Handbook",
        ],
        correctAnswer: "Occupational Health and Safety Policy"
      },
      {
        question: "What role do internal audits play in ISO 45001?",
        options: [
          "They are optional and conducted at the discretion of the company",
          "They are performed only by external auditors",
          "They help ensure the OHSMS is effectively implemented and maintained",
          "They focus primarily on financial performance",
        ],
        correctAnswer: "They help ensure the OHSMS is effectively implemented and maintained"
      },
      {
        question: "How often should the effectiveness of the OHSMS be reviewed according to ISO 45001?",
        options: [
          "Daily",
          "Monthly",
          "Annully",
          "As Needed"
        ],
        correctAnswer: "Annully"
      }
    ],
    iso22000: [
      {
        question: "What is the primary focus of ISO 22000?",
        options: [
          "Environmental Management",
          "Food Safety Management",
          "Information Security",
          "Occupational Health and Safety"
        ],
        correctAnswer: "Food Safety Management"
      },
      {
        question: "Which clause of ISO 22000 deals with Hazard Analysis and Critical Control Points (HACCP)?",
        options: [
          "Clause 6",
          " Clause 7",
          " Clause 8",
          "Clause 9"
        ],
        correctAnswer: " Clause 8"
      },
      {
        question: "What does FSMS stand for in the context of ISO 22000?",
        options: [
          "Food Safety Monitoring System",
          "Food Supply Management System",
          "Food Safety Management System",
          "Food Service Management System"
        ],
        correctAnswer: "Food Safety Management System"
      },
      {
        question: "Which of the following is NOT a core element of ISO 22000?",
        options: [
          "Prerequisite programs",
          "HACCP principles",
          "Quality control of non-food products",
          "Management system requirements"
        ],
        correctAnswer: "Quality control of non-food products"
      },
      {
        question: "What is the purpose of conducting a hazard analysis in ISO 22000?",
        options: [
          "To enhance financial performance",
          "To identify and assess potential food safety hazards",
          "To improve employee satisfaction",
          " To increase market share"
        ],
        correctAnswer: "To identify and assess potential food safety hazards"
      },
      {
        question: "Who is responsible for the implementation of the FSMS according to ISO 22000?",
        options: [
          " Middle management",
          "Quality control department",
          " Top management",
          "External auditors"
        ],
        correctAnswer: " Top management"
      },
      {
        question: "What does the term “critical control point” (CCP) mean in ISO 22000?",
        options: [
          "A step where control can be applied to prevent or eliminate a food safety hazard",
          "A location for financial auditing",
          "A point of customer service interaction",
          "A step in the marketing process"
        ],
        correctAnswer: "A step where control can be applied to prevent or eliminate a food safety hazard"
      },
      {
        question: "Which document outlines the scope of an organization's FSMS?",
        options: [
          "Quality Manual",
          "Food Safety Policy",
          "Financial Report",
          "Employee Handbook"
        ],
        correctAnswer: "Food Safety Policy"
      },
      {
        question: "What role do internal audits play in ISO 22000?",
        options: [
          "They are optional and conducted at the discretion of the company",
          "They are performed only by external auditors",
          "They help ensure the FSMS is effectively implemented and maintained",
          "They focus primarily on financial performance"
        ],
        correctAnswer: "They help ensure the FSMS is effectively implemented and maintained"
      },
      {
        question: "How often should the effectiveness of the FSMS be reviewed according to ISO 22000?",
        options: [
          "Daily",
          "Monthly",
          "Annully",
          "As Needed"
        ],
        correctAnswer: "Annully"
      }
    ],
    iso27001: [
      {
        question: "What is the primary focus of ISO 27001?",
        options: [
          " Quality Management",
          "Environmental Management",
          "Information Security Management",
          "Occupational Health and Safety"
        ],
        correctAnswer: "Information Security Management"
      },
      {
        question: "Which clause of ISO 27001 deals with the Context of the Organization?",
        options: [
          "Clause 4",
          "Clause 5",
          "Clause 6",
          "Clause 7"
        ],
        correctAnswer: "Clause 4"
      },
      {
        question: "What does ISMS stand for in the context of ISO 27001?",
        options: [
          "Information System Management Standard",
          "Information Security Management System",
          "International Security Management System",
          "Internet Security Management Standard"
        ],
        correctAnswer: "Information Security Management System"
      },
      {
        question: "Which of the following is NOT a core component of ISO 27001?",
        options: [
          "Risk Assessment",
          "Physical Security",
          "Financial Auditing",
          "Security Controls"
        ],
        correctAnswer: "Financial Auditing"
      },
      {
        question: "What is the purpose of a risk assessment in ISO 27001?",
        options: [
          "To evaluate financial performance",
          "To identify and manage information security risks",
          "To improve employee satisfaction",
          " To increase market share"
        ],
        correctAnswer: "To identify and manage information security risks"
      },
      {
        question: "Who is responsible for ensuring the ISMS is effectively implemented and maintained according to ISO 27001?",
        options: [
          "Middle management",
          " IT department",
          "Top management",
          "External auditors"
        ],
        correctAnswer: "Top management"
      },
      {
        question: "What is an information asset according to ISO 27001?",
        options: [
          "Any data that has financial value",
          "Any data that is valuable to the organization",
          "Any data that can be sold",
          "Any data that is not used frequently"
        ],
        correctAnswer: "Any data that is valuable to the organization"
      },
      {
        question: "Which document outlines the scope of an organization's ISMS?",
        options: [
          "Quality Manual",
          "Information Security Policy",
          " Financial Report",
          "Employee Handbook"
        ],
        correctAnswer: "Information Security Policy"
      },
      {
        question: "What role do internal audits play in ISO 27001?",
        options: [
          "They are optional and conducted at the discretion of the company",
          "They are performed only by external auditors",
          "They help ensure the ISMS is effectively implemented and maintained",
          "They focus primarily on financial performance"
        ],
        correctAnswer: "They help ensure the ISMS is effectively implemented and maintained"
      },
      {
        question: "How often should the effectiveness of the ISMS be reviewed according to ISO 27001?",
        options: [
          "Daily",
          "Monthly",
          "Annully",
          "As Needed"
        ],
        correctAnswer: "Annully"
      }
    ],
    iso22301: [
      {
        question: "What is the primary focus of ISO 22301?",
        options: [
          "Environmental Management",
          " Business Continuity Management",
          "Information Security",
          "Occupational Health and Safety"
        ],
        correctAnswer: " Business Continuity Management"
      },
      {
        question: "Which clause of ISO 22301 deals with Context of the Organization?",
        options: [
          "Clause 4",
          "Clause 5",
          "Clause 6",
          "Clause 7"
        ],
        correctAnswer: "Clause 4"
      },
      {
        question: "What is the purpose of a Business Impact Analysis (BIA) in ISO 22301?",
        options: [
          "To assess financial performance",
          "MonTo identify the impact of disruptions on business operationsthly",
          "To improve employee satisfaction",
          "To increase market share"
        ],
        correctAnswer: "To identify the impact of disruptions on business operations"
      },
      {
        question: "What does BCMS stand for in the context of ISO 22301?",
        options: [
          "Business Control Management System",
          "Business Continuity Management System",
          "Business Compliance Management Standard",
          "Business Communication Management System"
        ],
        correctAnswer: "Business Continuity Management System"
      },
      {
        question: "Which of the following is NOT a core element of ISO 22301?",
        options: [
          "Risk Assessment",
          "Financial Auditing",
          "Business Impact Analysis",
          "Incident Response"
        ],
        correctAnswer: "Financial Auditing"
      },
      {
        question: "Who is responsible for ensuring the BCMS is effectively implemented and maintained according to ISO 22301?",
        options: [
          "Middle management",
          "Business continuity team",
          "Top management",
          "External auditors"
        ],
        correctAnswer: "Top management"
      },
      {
        question: "What is the significance of a Business Continuity Plan (BCP) according to ISO 22301?",
        options: [
          "To outline financial strategies",
          "To ensure environmental compliance",
          "To establish procedures for responding to disruptions",
          "To enhance marketing efforts"
        ],
        correctAnswer: "To establish procedures for responding to disruptions"
      },
      {
        question: "Which document outlines the scope of an organization's BCMS?",
        options: [
          "Quality Manual",
          "Business Continuity Policy",
          "Financial Report",
          "Employee Handbook"
        ],
        correctAnswer: "Business Continuity Policy"
      },
      {
        question: "What role do internal audits play in ISO 22301?",
        options: [
          "They are optional and conducted at the discretion of the company",
          "They are performed only by external auditors",
          "They help ensure the BCMS is effectively implemented and maintained",
          "They focus primarily on financial performance"
        ],
        correctAnswer: "They help ensure the BCMS is effectively implemented and maintained"
      },
      {
        question: "How often should the effectiveness of the BCMS be reviewed according to ISO 22301?",
        options: [
          "Daily",
          "Monthly",
          "Annully",
          "As Needed"
        ],
        correctAnswer: "Annully"
      }
    ],
    iso20000: [
      {
        question: "What is the primary objective of ISO 20000-1?",
        options: [
          "Quality Management",
          "IT Service Management",
          "Environmental Management",
          "Occupational Health and Safety"
        ],
        correctAnswer: "IT Service Management"
      },
      {
        question: "Which clause of ISO 20000-1 deals with Service Delivery Processes?",
        options: [
          "Clause 4",
          "Clause 5",
          "Clause 6",
          " Clause 7"
        ],
        correctAnswer: " Clause 7"
      },
      {
        question: "What does ITSMS stand for in the context of ISO 20000-1?",
        options: [
          "Information Technology System Management",
          "Internet Technology Service Management",
          "IT Service Management System",
          " International Technology Standard Management"
        ],
        correctAnswer: "IT Service Management System"
      },
      {
        question: "Which of the following is NOT a core process of ISO 20000-1?",
        options: [
          "Incident Management",
          "Service Level Management",
          "Financial Auditing",
          "Change Management"
        ],
        correctAnswer: "Financial Auditing"
      },
      {
        question: "What is the purpose of a Service Level Agreement (SLA) according to ISO 20000-1?",
        options: [
          "To outline financial transactions",
          "To define the level of service expected by the customer",
          " To manage employee performance",
          "To ensure environmental compliance"
        ],
        correctAnswer: "To define the level of service expected by the customer"
      },
      {
        question: "Who is responsible for ensuring the ITSMS is effectively implemented and maintained according to ISO 20000-1?",
        options: [
          "Middle management",
          "IT department",
          "Top management",
          "External auditors"
        ],
        correctAnswer: "Top management"
      },
      {
        question: "What is an incident in the context of ISO 20000-1?",
        options: [
          "A planned change to a service",
          "An unplanned interruption to a service or reduction in the quality of a service",
          "A scheduled maintenance activity",
          "A financial transaction error"
        ],
        correctAnswer: "An unplanned interruption to a service or reduction in the quality of a service"
      },
      {
        question: "Which document outlines the scope of an organization's ITSMS?",
        options: [
          "Quality Manual",
          "Service Catalogue",
          "Financial Report",
          "Employee Handbook"
        ],
        correctAnswer: "Service Catalogue"
      },
      {
        question: "What role do internal audits play in ISO 20000-1?",
        options: [
          "They are optional and conducted at the discretion of the company",
          "They are performed only by external auditors",
          "They help ensure the ITSMS is effectively implemented and maintained",
          "They focus primarily on financial performance"
        ],
        correctAnswer: "They help ensure the ITSMS is effectively implemented and maintained"
      },
      {
        question: "How often should the effectiveness of the ITSMS be reviewed according to ISO 20000-1?",
        options: [
          "Daily",
          "Monthly",
          "Annully",
          "As Needed"
        ],
        correctAnswer: "Annully"
      }
    ],
    iso27701: [
      {
        question: "What is the primary focus of ISO 27701?",
        options: [
          "Quality Management",
          "Environmental Management",
          " Privacy Information Management",
          "Occupational Health and Safety"
        ],
        correctAnswer: " Privacy Information Management"
      },
      {
        question: "Which clause of ISO 27701 deals with Privacy Impact Assessments (PIAs)?",
        options: [
          "Clause 4",
          "Clause 5",
          "Clause 6",
          "Clause 7"
        ],
        correctAnswer: "Clause 7"
      },
      {
        question: "What does PIMS stand for in the context of ISO 27701?",
        options: [
          "Privacy Information Management System",
          "Public Information Management Standard",
          "Personal Information Management System",
          "Privacy Internal Management Scheme"
        ],
        correctAnswer: "Privacy Information Management System"
      },
      {
        question: "Which of the following is NOT a core component of ISO 27701?",
        options: [
          "Risk Assessment",
          "Data Protection Impact Assessment",
          "Financial Auditing",
          "Privacy Controls"
        ],
        correctAnswer: "Financial Auditing"
      },
      {
        question: "What is the purpose of a Data Protection Impact Assessment (DPIA) in ISO 27701?",
        options: [
          "To evaluate financial performance",
          "To identify and mitigate privacy risks",
          "To improve employee satisfaction",
          "To increase market share"
        ],
        correctAnswer: "To identify and mitigate privacy risks"
      },
      {
        question: "Who is responsible for ensuring the PIMS is effectively implemented and maintained according to ISO 27701?",
        options: [
          "Middle management",
          "IT department",
          "Top management",
          "External auditors"
        ],
        correctAnswer: "Top management"
      },
      {
        question: "What is a data subject according to ISO 27701?",
        options: [
          "An entity responsible for processing data",
          "An individual whose personal data is being processed",
          "A company that manages data",
          "A regulatory body overseeing data protection"
        ],
        correctAnswer: "An individual whose personal data is being processed"
      },
      {
        question: "Which document outlines the scope of an organization's PIMS?",
        options: [
          "Quality Manual",
          "Privacy Policy",
          "Financial Report",
          "Employee Handbook"
        ],
        correctAnswer: "Privacy Policy"
      },
      {
        question: "What role do internal audits play in ISO 27701?",
        options: [
          "They are optional and conducted at the discretion of the company",
          "They are performed only by external auditors",
          "They help ensure the PIMS is effectively implemented and maintained",
          "They focus primarily on financial performance"
        ],
        correctAnswer: "They help ensure the PIMS is effectively implemented and maintained"
      },
      {
        question: "How often should the effectiveness of the PIMS be reviewed according to ISO 27701?",
        options: [
          "Daily",
          "Monthly",
          "Annully",
          "As Needed"
        ],
        correctAnswer: "Annully"
      }
    ],
    iso13485: [
      {
        question: "What is the primary focus of ISO 13485?",
        options: [
          "Environmental Management",
          "Quality Management for Medical Devices",
          "Information Security",
          "Occupational Health and Safety"
        ],
        correctAnswer: "Quality Management for Medical Devices"
      },
      {
        question: "Which clause of ISO 13485 deals with Risk Management?",
        options: [
          "Clause 4",
          "Clause 7.1",
          "Clause 8.2",
          "Clause 6.2"
        ],
        correctAnswer: "Clause 7.1"
      },
      {
        question: "What is a key requirement of ISO 13485 related to medical device documentation?",
        options: [
          "It must be easy to read",
          "It must be organized and maintained in accordance with regulatory requirements",
          "It must be available in multiple languages",
          "It must be reviewed monthly"
        ],
        correctAnswer: "AnnulIt must be organized and maintained in accordance with regulatory requirementsly"
      },
      {
        question: "What does the term “Nonconforming Product” mean in ISO 13485?",
        options: [
          "A product that exceeds quality expectations",
          "A product that fails to meet specified requirements",
          "A product that is yet to be tested",
          " A product that is in high demand"
        ],
        correctAnswer: "A product that fails to meet specified requirements"
      },
      {
        question: "Which of the following is NOT a part of the ISO 13485 standard?",
        options: [
          "Design and Development",
          "Production and Service Provision",
          " Human Resource Management",
          "Corrective and Preventive Actions"
        ],
        correctAnswer: " Human Resource Management"
      },
      {
        question: "What is the purpose of internal audits according to ISO 13485?",
        options: [
          "To improve financial performance",
          "To identify areas for cost reduction",
          "To assess compliance with the QMS",
          "To ensure all employees are satisfied"
        ],
        correctAnswer: "To assess compliance with the QMS"
      },
      {
        question: "What must be established for maintaining the effectiveness of the QMS under ISO 13485?",
        options: [
          "Regular employee training",
          " High-quality marketing materials",
          "Communication procedures",
          "Detailed financial reports"
        ],
        correctAnswer: "Regular employee training"
      },
      {
        question: "Which ISO 13485 clause focuses on the management responsibility?",
        options: [
          "Clause 5",
          " Clause 6",
          " Clause 7",
          " Clause 8"
        ],
        correctAnswer: "Clause 5"
      },
      {
        question: "Why is risk management crucial in ISO 13485?",
        options: [
          " To increase product sales",
          "To improve employee morale",
          "To ensure the safety and performance of medical devices",
          "To reduce production costs"
        ],
        correctAnswer: "To ensure the safety and performance of medical devices"
      },
      {
        question: "How often should the effectiveness of the QMS be reviewed according to ISO 13485?",
        options: [
          "Daily",
          "Monthly",
          "Annully",
          "As Needed"
        ],
        correctAnswer: "Annully"
      }
    ],
    iso17025: [
      {
        question: "What is the primary focus of ISO 17025?",
        options: [
          "Environmental Management",
          "Occupational Health and Safety",
          "Competence of Testing and Calibration Laboratories",
          "Quality Management for Medical Devices",
        ],
        correctAnswer: "Competence of Testing and Calibration Laboratories",
      },
      {
        question: "Which clause in ISO 17025 deals with Management Requirements?",
        options: [
          "Clause 4",
          "Clause 5",
          "Clause 6",
          "Clause 7",
        ],
        correctAnswer: "Clause 4",
      },
      {
        question: "What is the purpose of proficiency testing according to ISO 17025?",
        options: [
          "To enhance financial performance",
          "To validate the competence of laboratory testing",
          "To improve employee satisfaction",
          "To increase market share",
        ],
        correctAnswer: "To validate the competence of laboratory testing",
      },
      {
        question: "What does the term calibration mean in the context of ISO 17025?",
        options: [
          "Adjusting equipment to meet new specifications",
          "Comparing measurements to a standard to ensure accuracy",
          "Replacing old equipment with new technology",
          "Performing routine maintenance on laboratory instruments",
        ],
        correctAnswer: "Comparing measurements to a standard to ensure accuracy",
      },
      {
        question: "Which of the following is NOT a requirement under ISO 17025?",
        options: [
          "Technical competenc",
          "Financial auditing",
          "Quality management",
          "Calibration of equipment",
        ],
        correctAnswer: "Financial auditing",
      },
      {
        question: "Who is responsible for the implementation of ISO 17025 in a laboratory?",
        options: [
          "Middle management",
          "Laboratory staff",
          "Top management",
          "External auditors",
        ],
        correctAnswer: "Top management",
      },
      {
        question: "What is the significance of traceability in ISO 17025?",
        options: [
          "To track financial transactions",
          "To ensure that measurements can be related to national or international standards",
          "To monitor employee performance",
          "To manage inventory effectively",
        ],
        correctAnswer: "To ensure that measurements can be related to national or international standards",
      },
      {
        question: "Which document outlines the general requirements for the competence of testing and calibration laboratories under ISO 17025?",
        options: [
          "Quality Manual",
          "Environmental Policy",
          "Laboratory Accreditation Standard",
          "Employee Handbook",
        ],
        correctAnswer: "Laboratory Accreditation Standard",
      },
      {
        question: "What is an interlaboratory comparison in the context of ISO 17025?",
        options: [
          "Comparing financial performance between laboratories",
          "Comparing test results from different laboratories to ensure consistency",
          "Comparing marketing strategies between laboratories",
          "Comparing human resource policies between laboratories",
        ],
        correctAnswer: "Comparing test results from different laboratories to ensure consistency",
      },
      {
        question: "How often should the effectiveness of the management system be reviewed according to ISO 17025?",
        options: [
          "Daily",
          "Monthly",
          "Annually",
          "As needed",
        ],
        correctAnswer: "Annually",
      },

    ],
    iso50001: [
      {
        question: "What is the primary focus of ISO 50001?",
        options: [
          "Quality Management",
          "Environmental Management",
          "Energy Management",
          "Occupational Health and Safety",
        ],
        correctAnswer: "Energy Management",
      },
      {
        question: "Which clause of ISO 50001 deals with Energy Planning?",
        options: [
          "Clause 4",
          "Clause 5",
          "Clause 6",
          "Clause 7",
        ],
        correctAnswer: "Clause 6",
      },
      {
        question: "What does EnMS stand for in the context of ISO 50001?",
        options: [
          "Environmental Management System",
          "Energy Management System",
          "Enterprise Management System",
          "Efficiency Management System",
        ],
        correctAnswer: "Energy Management System",
      },
      {
        question: "Which of the following is NOT a core component of ISO 50001?",
        options: [
          "Energy Review",
          "Energy Performance Indicators",
          "Financial Auditing",
          "Energy Baseline",
        ],
        correctAnswer: "Financial",
      },
      {
        question: "What is the purpose of an energy review in ISO 50001?",
        options: [
          "To evaluate financial performance",
          "To identify and evaluate energy use and consumption",
          "To improve employee satisfaction",
          "To increase market share",
        ],
        correctAnswer: "To identify and evaluate energy use and consumption",
      },
      {
        question: "Who is responsible for ensuring the EnMS is effectively implemented and maintained according to ISO 50001?",
        options: [
          "Middle management",
          "Energy management team",
          "Top management",
          "External auditors",
        ],
        correctAnswer: "Top management",
      },
      {
        question: "What is an energy performance indicator (EnPI) according to ISO 50001?",
        options: [
          "A measure of financial performance",
          "A metric used to quantify energy performance",
          "A target for reducing energy costs",
          "A benchmark for environmental compliance",
        ],
        correctAnswer: "A metric used to quantify energy performance",
      },
      {
        question: "Which document outlines the scope of an organization's EnMS?",
        options: [
          "Quality Manual",
          "Energy Policy",
          "Financial Report",
          "Employee Handbook",
        ],
        correctAnswer: "Energy Policy",
      },
      {
        question: "What role do internal audits play in ISO 50001?",
        options: [
          "They are optional and conducted at the discretion of the company",
          "They are performed only by external auditors",
          "They help ensure the EnMS is effectively implemented and maintained",
          "They focus primarily on financial performance",
        ],
        correctAnswer: "They help ensure the EnMS is effectively implemented and maintained",
      },
      {
        question: "How often should the effectiveness of the EnMS be reviewed according to ISO 50001?",
        options: [
          "Daily",
          "Monthly",
          "Annually",
          "As needed",
        ],
        correctAnswer: "Annually",
      },

    ],
    gmp: [
      {
        question: "What does GMP stand for in the context of manufacturing?",
        options: [
          "General Manufacturing Procedures",
          "Good Manufacturing Practices",
          "Global Manufacturing Policies",
          "General Manufacturing Policies",
        ],
        correctAnswer: "Good Manufacturing Practices",
      },
      {
        question: "Which of the following is a primary goal of GMP?",
        options: [
          "To ensure product quality and safety",
          "To increase production speed",
          "To reduce employee workload",
          "To enhance marketing strategies",
        ],
        correctAnswer: "To ensure product quality and safety",
      },
      {
        question: "What is the significance of maintaining clean and hygienic manufacturing environments according to GMP?",
        options: [
          "To prevent contamination",
          "Monthly",
          "To reduce energy consumption",
          "To enhance product packaging",
        ],
        correctAnswer: "To prevent contamination",
      },
      {
        question: "Which of the following is NOT a component of GMP?",
        options: [
          "Personnel qualifications",
          "Equipment maintenance",
          "Financial auditing",
          "Quality control",
        ],
        correctAnswer: "Financial auditing",
      },
      {
        question: "What is the purpose of GMP documentation?",
        options: [
          "To provide legal protection",
          "To ensure consistency and traceability",
          "To enhance marketing efforts",
          "To reduce production costs",
        ],
        correctAnswer: "To ensure consistency and traceability",
      },
      {
        question: "Who is responsible for implementing GMP in a manufacturing facility?",
        options: [
          "Only the quality control department",
          "All employees",
          "Only the top management",
          "External auditors",
        ],
        correctAnswer: "All employees",
      },
      {
        question: "What does GMP require regarding the handling of raw materials?",
        options: [
          "They should be stored in any available space",
          "They should be inspected upon arrival",
          "They should be used immediately",
          "They should be stored without labels",
        ],
        correctAnswer: "They should be inspected upon arrival",
      },
      {
        question: "Which of the following is a key principle of GMP?",
        options: [
          "Minimizing employee training",
          "Ignoring equipment maintenance",
          "Ensuring proper sanitation",
          "Reducing documentation",
        ],
        correctAnswer: "Ensuring proper sanitation",
      },
      {
        question: "What is the role of GMP in product recalls?",
        options: [
          "To complicate the recall process",
          "To ensure quick and effective recalls",
          "To avoid recalls altogether",
          "To increase recall costs",
        ],
        correctAnswer: "To ensure quick and effective recalls",
      },
      {
        question: "How often should GMP training be conducted for employees?",
        options: [
          "Once at hiring",
          "Annually",
          "Every five years",
          "Only when there are changes in procedures",
        ],
        correctAnswer: "Annually",
      },

    ],
    haccp: [
      {
        question: "What does HACCP stand for?",
        options: [
          "Hazard Analysis and Critical Control Points",
          "Health and Safety Control Plan",
          "Hazard Assessment and Control Procedures",
          "High Accuracy Control Protocol",
        ],
        correctAnswer: "Hazard Analysis and Critical Control Points",
      },
      {
        question: "What is the primary purpose of HACCP?",
        options: [
          "To improve financial performance",
          "To ensure food safety",
          "To reduce employee workload",
          "To enhance marketing strategies",
        ],
        correctAnswer: "To ensure food safety",
      },
      {
        question: "Which of the following is the first principle of HACCP?",
        options: [
          "Establish critical limits",
          "Conduct a hazard analysis",
          "Establish corrective actions",
          "Verify the system works",
        ],
        correctAnswer: "Conduct a hazard analysis",
      },
      {
        question: "What are Critical Control Points (CCPs) in the context of HACCP?",
        options: [
          "Steps where financial audits are performed",
          "ProcSteps where control can be applied to prevent or eliminate a food safety hazardssing",
          "Points where employee performance is evaluated",
          "Steps where marketing strategies are developed",
        ],
        correctAnswer: "Steps where control can be applied to prevent or eliminate a food safety hazard",
      },
      {
        question: "Which HACCP principle involves establishing procedures to monitor CCPs?",
        options: [
          "Principle 2",
          "Principle 3",
          "Principle 4",
          "Principle 5",
        ],
        correctAnswer: "Principle 4",
      },
      {
        question: "Why is it important to establish critical limits in HACCP?",
        options: [
          "To improve financial performance",
          "To define acceptable levels of hazards at CCPs",
          "To increase production speed",
          "To enhance product packaging",
        ],
        correctAnswer: "To define acceptable levels of hazards at CCPs",
      },
      {
        question: "What should be done if a critical limit is not met according to HACCP principles?",
        options: [
          "Ignore the issue",
          "Establish corrective actions to bring the process back under control",
          "Report it to the marketing team",
          "Increase production speed",
        ],
        correctAnswer: "Establish corrective actions to bring the process back under control",
      },
      {
        question: "Which of the following is NOT a principle of HACCP?",
        options: [
          "Conduct a hazard analysis",
          "Establish critical limits",
          "Perform a financial audit",
          "Verify the system works",
        ],
        correctAnswer: "Perform a financial audit",
      },
      {
        question: "What is the role of verification procedures in HACCP?",
        options: [
          "To ensure marketing strategies are effective",
          "To confirm that the HACCP plan is working correctly",
          "To assess employee performance",
          "To increase production efficiency",
        ],
        correctAnswer: "To confirm that the HACCP plan is working correctly",
      },
      {
        question: "How often should the HACCP system be reviewed?",
        options: [
          "Daily",
          "Monthly",
          "Annually",
          "As needed",
        ],
        correctAnswer: "As needed",
      },
    ],
    soc2: [
      {
        question: "What is the primary focus of SOC 2?",
        options: [
          "Financial Reporting",
          "Customer Service",
          "Trust Service Principles",
          "Environmental Management",
        ],
        correctAnswer: "Trust Service Principles",
      },
      {
        question: "Which of the following is NOT one of the five trust service principles in SOC 2?",
        options: [
          "Security",
          "Confidentiality",
          "Financial Control",
          "Processing Integrity",
        ],
        correctAnswer: "Financial Control",
      },
      {
        question: "What does SOC in SOC 2 stand for?",
        options: [
          "Service Organization Control",
          "Security of Control",
          "Systems Operating Control",
          "Standards of Compliance",
        ],
        correctAnswer: "Service Organization Control",
      },
      {
        question: "Which SOC 2 principle focuses on protecting data from unauthorized access?",
        options: [
          "Privacy",
          "Availability",
          "Security",
          "Processing Integrity",
        ],
        correctAnswer: "Security",
      },
      {
        question: "What is the purpose of a SOC 2 audit?",
        options: [
          "To evaluate financial performance",
          "To assess compliance with trust service principles",
          "To improve customer satisfaction",
          "To enhance marketing strategies",
        ],
        correctAnswer: "To assess compliance with trust service principles",
      },
      {
        question: "Who typically performs a SOC 2 audit?",
        options: [
          "Internal employees",
          "External auditors",
          "Customers",
          "IT department",
        ],
        correctAnswer: "External auditors",
      },
      {
        question: "What is a key benefit of achieving SOC 2 certification?",
        options: [
          "Increased production speed",
          "Enhanced data security and trustworthiness",
          "Reduced marketing costs",
          "Higher employee turnover",
        ],
        correctAnswer: "Enhanced data security and trustworthiness",
      },
      {
        question: "Which SOC 2 principle addresses the need for the system to be available for operation and use as agreed?",
        options: [
          "Security",
          "Privacy",
          "Availability",
          "Confidentiality",
        ],
        correctAnswer: "Availability",
      },
      {
        question: "How often should SOC 2 audits be conducted to ensure ongoing compliance?",
        options: [
          "Daily",
          "Monthly",
          "Annually",
          "Every five years",
        ],
        correctAnswer: "Annually",
      },
      {
        question: "Which SOC 2 principle relates to how personal information is collected, used, retained, and disclosed?",
        options: [
          "Security",
          "Processing",
          "Privacy",
          "Confidentiality",
        ],
        correctAnswer: "Privacy",
      },
    ],
  };