import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "./Navbar/Navbar";
import Bg from "../Images/BgImg.png";
import { BsCheckCircleFill, BsFillCheckCircleFill } from "react-icons/bs";

const Module2 = () => {
  const navigate = useNavigate();
  const [steps, setSteps] = useState([false, false, false, false]);
  const step = [
    "Choose Module",
    "Select Certification",
    "Attend Quiz",
    "Google Review",
  ];

  const handleCertificationSelect = (e) => {
    const selectedCertification = e.target.value;
    if (selectedCertification) {
      setSteps((prevSteps) => {
        const newSteps = [...prevSteps];
        newSteps[0] = true;
        return newSteps;
      });
      navigate(`/quiz/module2/${selectedCertification}`, { state: { steps: [true, true, false, false] } });
    }
  };

  return (
    <div className="flex flex-col min-h-screen">
      <div className="flex-shrink-0">
        <Navbar />
      </div>

      <div
        className="relative flex-grow px-4 py-8 md:flex-row items-start"
        style={{
          backgroundImage: `url(${Bg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        {/* Breadcrumbs */}
        <div className="flex justify-center items-center mb-8 ml-6 text-xl space-x-6">
          {step.map((step, index) => (
            <div key={index} className="flex items-center space-x-4">
              <div className="flex items-center">
                <div
                  className={`w-8 h-8 flex items-center justify-center rounded-full ${
                    steps[index] ? "bg-green-500 text-white" : "bg-gray-300"
                  }`}
                >
                  {steps[index] ? <BsCheckCircleFill /> : index + 1}
                </div>
                {index < 3 && <div className="w-8 h-[2px] bg-gray-400"></div>}
              </div>
              <p className="text-xl text-white font-bold text-gray-800">
                {step}
              </p>
            </div>
          ))}
        </div>

        <div
          className="relative w-full flex flex-col md:flex-row text-gray-800 z-10 p-6 rounded-lg  h-[400px]"
          style={{
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
          }}
        >
          <div className="w-full md:w-3/5">
            <h1 className="text-4xl font-bold mb-4">
              About This Lead Implementation
            </h1>
            <p className="text-lg leading-relaxed text-left text-justify">
              This module provides detailed information about the benefits and
              implementation of ISO certifications in various industries. ISO
              certifications ensure quality, efficiency, and safety in
              operations and compliance with international standards.
            </p>
          </div>

          <div className="w-full md:w-2/5 md:pl-8 mt-8 md:mt-0">
            <h2 className="text-2xl font-semibold mb-4">ISO Certifications</h2>
            <select
              onChange={handleCertificationSelect}
              className="w-full p-3 border border-gray-300 rounded-lg text-gray-700 bg-white"
              defaultValue=""
            >
              <option value="" disabled>
                Select ISO Certification
              </option>
              <option value="iso9001">ISO 9001 - Quality Management</option>
              <option value="iso14001">
                ISO 14001 - Environmental Management
              </option>
              <option value="iso45001">
                ISO 45001 - Occupational Health & Safety
              </option>
              <option value="iso22000">
                ISO 22000 - Food Safety Management
              </option>
              <option value="iso27001">
                ISO 27001 - Information Security Management
              </option>
              <option value="iso22301">
                ISO 22301 - Business Continuity Management Standard
              </option>
              <option value="iso20000">
                ISO 20000-1 - International Standard for Service Management
              </option>
              <option value="iso27701">
                ISO 27701 - Privacy Information Management
              </option>
              <option value="iso13485">ISO 13485 - Medical Devices</option>
              <option value="iso17025">
                ISO 17025 - Testing and Calibration Laboratories
              </option>
              <option value="iso50001">ISO 50001 - Energy Management</option>
              <option value="gmp">GMP</option>
              <option value="haccp">HACCP</option>
              <option value="soc2">SOC 2</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Module2;
