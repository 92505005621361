import React, { useState, useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import Navbar from "./Navbar/Navbar";
import { motion, AnimatePresence } from "framer-motion";
import { questionsBank } from "./QuizData1";
import { BsCheckCircleFill } from "react-icons/bs";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Module1Quiz = () => {
  const { certification } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState({});
  const [score, setScore] = useState(null);
  const [quizAttempted, setQuizAttempted] = useState(false);
  const [isFirstAttempt, setIsFirstAttempt] = useState(true); // Track if this is the first attempt
  const [isCertificateAvailable, setIsCertificateAvailable] = useState(false);
  const [certificateDownloaded, setCertificateDownloaded] = useState(false);
  const [steps, setSteps] = useState(
    location.state?.steps || [false, false, false, false]
  ); // Track completion of each step
  const step = [
    "Choose Module",
    "Select Certification",
    "Attend Quiz",
    "Google Review",
  ];

  const localStorageKey = `quiz_${certification}_attempted`;

  useEffect(() => {
    const attempted = localStorage.getItem(localStorageKey);
    if (attempted) {
      setQuizAttempted(true);
      setIsFirstAttempt(false);
    } else {
      const selectedQuestions = questionsBank[certification] || [];
      const randomQuestions = selectedQuestions
        .sort(() => Math.random() - 0.5)
        .slice(0, 10);
      setQuestions(randomQuestions);
    }
  }, [certification, localStorageKey]);

  const handleAnswerChange = (questionIndex, answer) => {
    setAnswers((prev) => ({
      ...prev,
      [questionIndex]: answer,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let correctAnswers = 0;
    questions.forEach((q, index) => {
      if (answers[index] === q.correctAnswer) {
        correctAnswers += 1;
      }
    });
    setScore(correctAnswers);

    setSteps((prevSteps) => {
      const updatedSteps = [...prevSteps];
      updatedSteps[2] = true; // Step 3 becomes true
      return updatedSteps;
    });

    if (!quizAttempted) {
      localStorage.setItem(localStorageKey, true);
      setQuizAttempted(true);
    }
    setIsFirstAttempt(!quizAttempted);
  };

  const handleButtonClick = () => {
    if (!isCertificateAvailable) {
      window.open(
        "https://www.google.com/search?q=certvalue+google+reviews#lrd=0x3bae159cb9a01cdb:0x3112aea4314f37ce,3,,,,",
        "_blank"
      );

      setIsCertificateAvailable("pending");

      setTimeout(() => {
        setIsCertificateAvailable(true);
      }, 5000);
    }
  };

  const handleCertificateDownload = () => {
    // Show toast notification
    toast.success(
      " Please give the Google Review!...We will contact you soon! Certificate will be sent to the registered Email.",
      {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }
    );

    setCertificateDownloaded(false);
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <Navbar />

      <div className="container mx-auto px-4 py-16">
        {/* Breadcrumbs */}
        <div className="flex justify-center items-center mb-8 ml-6 text-xl space-x-6">
          {step.map((step, index) => (
            <div key={index} className="flex items-center space-x-4">
              <div className="flex items-center">
                <div
                  className={`w-8 h-8 flex items-center justify-center rounded-full ${
                    steps[index] ? "bg-green-500 text-white" : "bg-gray-300"
                  }`}
                >
                  {steps[index] ? <BsCheckCircleFill /> : index + 1}
                </div>
                {index < 3 && <div className="w-8 h-[2px] bg-gray-400"></div>}
              </div>
              <p className="text-xl font-bold text-gray-800">{step}</p>
            </div>
          ))}
        </div>

        <h1 className="text-center text-4xl font-bold text-gray-800 mb-12">
          ISO Certification Quiz - {certification.toUpperCase()}
        </h1>

        {quizAttempted && score === null ? (
          <div className="text-center">
            <p className="text-lg text-gray-700 font-medium">
              You have already attempted this quiz. Thank you!
            </p>
            <button
              onClick={
                isCertificateAvailable === true
                  ? handleCertificateDownload
                  : handleButtonClick
              }
              className={`bg-green-600 text-white py-2 px-4 mt-8 rounded-lg hover:bg-green-700 transition duration-300 ${
                isCertificateAvailable === "pending" ? "cursor-not-allowed" : ""
              }`}
              disabled={isCertificateAvailable === "pending"}
            >
              {isCertificateAvailable === true
                ? "Download Certificate"
                : isCertificateAvailable === "pending"
                ? "Certificate will be available after the review..."
                : "Write a Google Review"}
            </button>
          </div>
        ) : (
          <form onSubmit={handleSubmit}>
            {questions.map((q, index) => (
              <div key={index} className="mb-6">
                <p className="text-lg font-semibold text-gray-800 mb-2">
                  {q.question}
                </p>
                {q.options?.map((option, idx) => (
                  <div key={idx} className="flex items-center space-x-3">
                    <input
                      type="radio"
                      id={`q${index}_option${idx}`}
                      name={`q${index}`}
                      value={option}
                      checked={answers[index] === option}
                      onChange={() => handleAnswerChange(index, option)}
                      className="text-blue-600"
                    />
                    <label
                      htmlFor={`q${index}_option${idx}`}
                      className="text-gray-600"
                    >
                      {option}
                    </label>
                  </div>
                ))}
              </div>
            ))}

            <button
              type="submit"
              className="bg-blue-600 text-white py-1 px-4 rounded-lg hover:bg-blue-700 transition duration-300 text-sm"
            >
              Submit Quiz
            </button>
          </form>
        )}

        <AnimatePresence>
          {score !== null && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center z-50"
            >
              <div className="bg-white rounded-lg p-6 w-11/12 max-w-lg shadow-lg">
                <h2 className="text-2xl font-bold text-gray-800 mb-4 text-center">
                  {isFirstAttempt
                    ? `Quiz Submitted Successfully!`
                    : `You have already attempted this quiz.`}
                </h2>
                <p className="text-lg text-gray-600 mb-6 text-center">
                  Your score: {score} out of {questions.length}
                </p>
                <div className="flex justify-center">
                  <div className="flex justify-center">
                    <button
                      onClick={
                        isCertificateAvailable === true
                          ? handleCertificateDownload
                          : handleButtonClick
                      }
                      className={`bg-green-600 text-white py-2 px-4 rounded-lg hover:bg-green-700 transition duration-300 ${
                        isCertificateAvailable === "pending"
                          ? "cursor-not-allowed"
                          : ""
                      }`}
                      disabled={isCertificateAvailable === "pending"}
                    >
                      {isCertificateAvailable === true
                        ? "Download Certificate"
                        : isCertificateAvailable === "pending"
                        ? "Certificate will be available after the review..."
                        : "Write a Google Review"}
                    </button>
                  </div>
                </div>
                {certificateDownloaded ||
                isCertificateAvailable === "pending" ? (
                  <button
                    className="mt-4 block mx-auto text-blue-500 hover:underline"
                    onClick={() => setScore(null)}
                  >
                    Close
                  </button>
                ) : null}
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

export default Module1Quiz;
